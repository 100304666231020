import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import AuthStore from '../lib/stores/auth-store';

export default function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        AuthStore.isLoggedIn() ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
}
