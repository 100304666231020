import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthStore from '../../lib/stores/auth-store';
import { authApi } from '../../api/authApi';

class LogoutScreen extends React.Component {
  componentWillMount() {
    AuthStore.logoutUser();
    authApi.logout().then(() => {});
  }

  render() {
    return <Redirect to="/login" />;
  }
}

export default LogoutScreen;
