import { call, put } from 'redux-saga/effects';

import { fetchDebuglogsError, fetchDebuglogsSuccess } from '../actions';
import { parseApiError } from '../../../api/helpers';
import { debuglogApi } from '../../../api/debuglog';

export function* fetchDebuglogsSaga() {
  try {
    const response = yield call(debuglogApi.fetch);

    if (!response.ok) {
      yield put(fetchDebuglogsError(parseApiError(response).message));
      return;
    }

    yield put(fetchDebuglogsSuccess(response.data.data.results || []));
  } catch (err) {
    yield put(fetchDebuglogsError(parseApiError(err).message));
  }
}
