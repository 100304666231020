import { call, put } from 'redux-saga/effects';

import { fetchOffersError, fetchOffersSuccess } from '../actions';
import { parseApiError } from '../../../api/helpers';
import { offerApi } from '../../../api/offer';

export function* fetchOffersSaga() {
  try {
    const response = yield call(offerApi.fetch);

    if (!response.ok) {
      yield put(fetchOffersError(parseApiError(response).message));
      return;
    }

    yield put(fetchOffersSuccess(response.data.data.results || []));
  } catch (err) {
    yield put(fetchOffersError(parseApiError(err).message));
  }
}
