import produce from 'immer';
import {
  UI_CLOSE_DRAWER,
  UI_OPEN_DRAWER,
  UI_PROGRESS_BAR_CLOSE,
  UI_PROGRESS_BAR_OPEN,
  UI_SET_PAGE_TITLE,
  UI_TOGGLE_DRAWER,
} from '../actions';

let isDrawerOpen = localStorage.getItem('isDrawerOpen');
if (isDrawerOpen === null) {
  isDrawerOpen = 1;
}

const INITIAL_STATE = {
  progressBarStatus: 'CLOSED',
  pageTitle: '',
  drawerOpen: !!parseInt(isDrawerOpen, 10),
};

export default function ui(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case UI_PROGRESS_BAR_OPEN:
        draft.progressBarStatus = 'OPEN';

        break;

      case UI_PROGRESS_BAR_CLOSE:
        draft.progressBarStatus = 'CLOSED';

        break;

      case UI_OPEN_DRAWER:
        draft.drawerOpen = true;

        break;

      case UI_CLOSE_DRAWER:
        draft.drawerOpen = false;

        break;

      case UI_TOGGLE_DRAWER:
        draft.drawerOpen = !state.drawerOpen;

        break;

      case UI_SET_PAGE_TITLE:
        draft.pageTitle = action.payload.title;
        window.document.title = draft.pageTitle;

        break;

      default:
        break;
    }
  });
}
