import React from 'react';
import find from 'lodash/find';
import roles from './roles';

export function findActivePage(currentPages, pathname) {
  const activePage = find(currentPages, page => {
    if (page.children) {
      // if (pathname.indexOf(`${page.pathname}/`) === 0) {
      // Check if one of the children matches (for /components)
      return findActivePage(page.children, pathname);
      // }
    }

    // Should be an exact match if no children
    return pathname === page.pathname;
  });

  if (!activePage) {
    return null;
  }

  // We need to drill down
  if (activePage.pathname !== pathname) {
    return findActivePage(activePage.children, pathname);
  }

  return activePage;
}

const pages = [
  /* {
    pathname: '/dashboard',
    requireRole: [roles.ADMIN, roles.MANAGER],
    title: 'Panou control',
    icon: <i className="fal fa-analytics" />,
    children: [
      { pathname: '/dashboard', title: 'Sumar', requireRole: [roles.ADMIN, roles.MANAGER] },
       { pathname: '/dashboard/stats', title: 'Statistici', requireRole: [roles.ADMIN, roles.MANAGER] },
    ],
  }, */

  {
    pathname: '/dashboard',
    requireRole: [roles.ADMIN, roles.MANAGER],
    title: 'Panou control',
    icon: <i className="fal fa-analytics" />,
  },

  {
    pathname: '/offer',
    requireRole: [roles.ADMIN, roles.MANAGER],
    title: 'Lucrari',
    icon: <i className="fal fa-cctv" />,
    children: [
      /* { pathname: '/offer/add', title: 'Adauga lucrare', requireRole: [roles.ADMIN, roles.MANAGER] }, */
      { pathname: '/offer/new', title: 'Disponibile', requireRole: [roles.ADMIN, roles.MANAGER] },
      { pathname: '/offer/accepted', title: 'Preluate', requireRole: [roles.ADMIN, roles.MANAGER] },
      { pathname: '/offer/started', title: 'In desfasurare', requireRole: [roles.ADMIN, roles.MANAGER] },
      { pathname: '/offer/completed', title: 'Finalizate', requireRole: [roles.ADMIN, roles.MANAGER] },
      { pathname: '/offer', title: 'Toate lucrarile', requireRole: [roles.ADMIN, roles.MANAGER] },
    ],
  },

  {
    pathname: '/user',
    requireRole: [roles.ADMIN, roles.MANAGER],
    title: 'Utilizatori',
    icon: <i className="fal fa-user-friends" />,
    children: [
      { pathname: '/user/pending', title: 'In asteptare', requireRole: [roles.ADMIN, roles.MANAGER] },
      { pathname: '/user', title: 'Toti utilizatorii', requireRole: [roles.ADMIN, roles.MANAGER] },
      { pathname: '/ratings', title: 'Evaluari', requireRole: [roles.ADMIN, roles.MANAGER] },
      { pathname: '/debug-log', title: 'Cereri suport', requireRole: [roles.ADMIN, roles.MANAGER] },
    ],
  },

  {
    pathname: '/notification',
    requireRole: [roles.ADMIN, roles.MANAGER],
    title: 'Notificari',
    icon: <i className="fal fa-megaphone" />,
    children: [
      { pathname: '/notification', title: 'Notificari', requireRole: [roles.ADMIN, roles.MANAGER] },
      { pathname: '/notification/send', title: 'Trimite notificare', requireRole: [roles.ADMIN, roles.MANAGER] },
    ],
  },

  {
    pathname: '/system-settings',
    requireRole: [roles.ADMIN, roles.MANAGER],
    title: 'Configurare sistem',
    icon: <i className="fal fa-cog" />,
  },
];

export default pages;
