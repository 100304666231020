import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import { setPageTitle } from '../../ui/actions';
import { enqueueSnackbar } from '../../snackbar/actions';
import SendNotification from '../components/send';
import { fetchUsers } from '../../user/actions';

function mapStateToProps(state, ownProps) {
  const sortedUsers = sortBy(Object.values(state.users.entities.users), [
    (user) => (user.first_name ? user.first_name.toLowerCase() : ''),
    (user) => (user.last_name ? user.last_name.toLowerCase() : ''),
  ]);

  return {
    ...ownProps,
    users: sortedUsers,
    usersById: state.users.entities.users || {},
  };
}

const mapDispatchToProps = {
  setPageTitle,
  enqueueSnackbar,
  fetchUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendNotification);
