import produce from 'immer';
import { denormalize, normalize, schema } from 'normalizr';
import _ from 'lodash';

import {
  USERS_FETCH_REQUEST,
  USERS_FETCH_SUCCESS,
  USERS_FETCH_ERROR,
  USERS_HIDE_ADD_DIALOG,
  USERS_SHOW_ADD_DIALOG,
  USERS_SET_TABLE_FILTER,
  USERS_FETCH_USER_SUCCESS,
  USERS_LOADING_START,
  USERS_LOADING_STOP,
} from '../actions';

const INITIAL_STATE = {
  entities: {
    users: {},
  },
  entitiesIds: [],
  selected: null,

  // Filters
  filters: {
    search: null,
    status: 'active',
  },

  // Other state
  showAddDialog: false,
  loading: false,
  initialLoaded: false,
  loadingId: null,
  loadingIds: [],

  fetchError: null,
  saveError: null,
};

// Define the order schema so we can normalize the data and index it properly
const userSchema = new schema.Entity('users');
const userListSchema = new schema.Array(userSchema);

export default function users(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case USERS_FETCH_SUCCESS:
        // Normalize the data for better access
        const { data } = action.payload;
        const normalized = normalize(data, userListSchema);
        draft.entities = normalized.entities;
        draft.entitiesIds = normalized.result;
        draft.loading = false;
        draft.initialLoaded = true;
        draft.fetchError = null;

        break;

      case USERS_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case USERS_FETCH_ERROR:
        draft.fetchError = action.payload.error;
        break;

      // -------

      case USERS_FETCH_USER_SUCCESS:
        let usersArr = denormalize(state.entitiesIds, userListSchema, state.entities);
        const rowIndex = state.entitiesIds.indexOf(parseInt(action.payload.id, 10));

        if (rowIndex === -1) {
          usersArr = [...usersArr, action.payload.data];
        } else {
          usersArr = [...usersArr.slice(0, rowIndex), action.payload.data, ...usersArr.slice(rowIndex + 1)];
        }

        const usersUpdated = normalize(usersArr, userListSchema);
        draft.entities = usersUpdated.entities;
        draft.entitiesIds = usersUpdated.result;
        break;

      // -------

      case USERS_HIDE_ADD_DIALOG:
        draft.showAddDialog = false;
        break;

      case USERS_SHOW_ADD_DIALOG:
        draft.showAddDialog = true;
        break;

      case USERS_SET_TABLE_FILTER:
        draft.filters[action.payload.name] = action.payload.value;
        break;

      case USERS_LOADING_START:
        if (state.loadingIds.indexOf(parseInt(action.payload.id, 10)) === -1) {
          draft.loadingIds = [...state.loadingIds, parseInt(action.payload.id, 10)];
        }
        break;

      case USERS_LOADING_STOP:
        draft.loadingIds = state.loadingIds.filter(el => el !== parseInt(action.payload.id, 10));
        break;

      default:
    }
  });
}

// Selectors
export function getFilteredUsers(state, forceStatus = null) {
  const { entities, filters } = state.users;

  return _.filter(entities.users, o => {
    if (forceStatus && o.status !== forceStatus) {
      return false;
    }

    if (filters.search) {
      if (
        o.first_name.toLowerCase().indexOf(filters.search.toLowerCase()) === -1 &&
        o.last_name.toLowerCase().indexOf(filters.search.toLowerCase()) === -1 &&
        o.email_address.toLowerCase().indexOf(filters.search.toLowerCase()) === -1
      ) {
        return false;
      }
    }

    if (filters.role && o.role !== filters.role) {
      return false;
    }

    if (filters.status && o.status !== filters.status) {
      return false;
    }

    if (filters.channel) {
      if (!o.channels || !o.channels.length) {
        return false;
      }

      if (o.channels.findIndex(c => parseInt(c.id) === parseInt(filters.channel)) === -1) {
        return false;
      }
    }

    return true;
  });
}

export const getUserByIdSelector = (state, id) => {
  return state.users && state.users.entities && state.users.entities.users ? state.users.entities.users[id] : {};
};
