import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Link,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import AuthStore from '../../lib/stores/auth-store';
import { authApi } from '../../api/authApi';
import { parseApiError } from '../../api/helpers';

import { styles } from './login.styles';

@withStyles(styles)
@withRouter
class LoginScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      errorMsg: false,
      loading: false,
      username: '',
      password: '',
      rememberMe: false,
      redirectToDashboard: false,
      refreshingPage: false,
    };
  }

  async componentWillMount() {
    // When we mount, we check to see if maybe we are logged in, but
    // got still landed on the login page
    // If that's the case, we clear the session and refresh the page
    if (AuthStore.isLoggedIn()) {
      await authApi.logout();
      AuthStore.logoutUser();

      // Refresh the page
      this.setState({ refreshingPage: true }, () => setTimeout(() => (window.location = '/login'), 500));
    }
  }

  login = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    this.setState({
      loading: true,
      error: false,
      errorMsg: false,
    });

    const { location } = this.props;
    const { username, password, rememberMe } = this.state;
    try {
      const response = await authApi.login(username, password);

      if (!response.ok || !response.data.token) {
        this.setState({
          error: true,
          errorMsg: parseApiError(response, 'Datele de autentificare nu au putut fi validate. Incearca din nou.')
            .message,
          loading: false,
        });

        return;
      }

      await AuthStore.loginUser(response.data.token, response.data.user);

      // Do we have a referrer?
      const redirectToUrl = (location && location.state && location.state.from && location.state.from.pathname) || '/';
      window.location = redirectToUrl;
    } catch (err) {
      this.setState({
        loading: false,
        error: true,
        errorMsg: parseApiError(err).message,
      });
    }
  };

  render() {
    const { classes, location } = this.props;
    const { loading, error, errorMsg, redirectToDashboard, redirectToUrl, refreshingPage, rememberMe } = this.state;

    if (refreshingPage) {
      return null;
    }

    if (redirectToUrl) {
      // Make sure the URL is not the same as the current page
      if (redirectToUrl.pathname !== '/login') {
        return <Redirect to={redirectToUrl} />;
      }
    }

    if (redirectToDashboard) {
      const { from } = (location && location.state) || { from: { pathname: '/' } };
      return <Redirect to={from} />;
    }

    return (
      <main className={classes.main}>
        <CssBaseline />

        <Box className={classes.logo}>
          <img className={classes.logoImg} src="/spy-shop-logo.svg" alt="Spy Shop B2B" />
          <span className={classes.logoText}>B2B</span>
        </Box>

        <Paper className={classes.paper} elevation={0}>
          <Typography component="h1" variant="h5" className="mb-2">
            Intra in cont
          </Typography>

          <Typography
            component="p"
            variant="body2"
            className={classes.error}
            style={{ visibility: errorMsg ? 'visible' : 'hidden' }}>
            {errorMsg || '&nbsp;'}
          </Typography>

          <form className={clsx(classes.form, classes.formLogin)} onSubmit={this.login}>
            <Box>
              <TextField
                className={classes.input}
                fullWidth
                label="Utilizator / Email"
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                error={!!errorMsg && !this.state.username}
                onChange={ev => this.setState({ username: ev.target.value })}
              />

              <TextField
                fullWidth
                label="Parola"
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={!!errorMsg && !this.state.password}
                onChange={ev => this.setState({ password: ev.target.value })}
              />
            </Box>

            <Box className="d-flex justify-content-between align-items-center">
              <Box className="mt-2">
                <FormControlLabel control={<Checkbox checked={!!rememberMe} onChange={ev => this.setState({ rememberMe: !!ev.target.checked })} name="remember_me" />} label="Tine-ma minte" />
              </Box>
              {/*<Box className="mt-2">
                <Link href="#" onClick={() => {}}>
                  Ai uitat parola?
                </Link>
              </Box>*/}
            </Box>

            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              className={classes.submit}>
              Autentificare
            </Button>

            <Typography className={classes.copyright}>Spy Shop SRL &copy; Toate drepturile rezervate</Typography>
          </form>
        </Paper>
      </main>
    );
  }
}

export default LoginScreen;
