import React from 'react';
import PropTypes from 'prop-types';
import { FormElement } from './element';

const FormRow = ({ children, cols, ...rest }) => {
  const classes = ['d-flex '];
  if (!Array.isArray(children)) {
    children = [children];
  }

  return (
    <div {...rest} className={classes.join(' ')}>
      {children.map((element, idx) => (
        <FormElement key={`fr-${idx}`} childrenCount={cols || children.length || 1}>
          {element}
        </FormElement>
      ))}
    </div>
  );
};

FormRow.propTypes = {
  cols: PropTypes.number,
};

export { FormRow };
export default FormRow;
