import React from 'react';
import { Box, Typography, withStyles } from '@material-ui/core';

import { withSnackbar } from 'notistack';

import styles from '../styles';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import ContentPaper from '../../../components/content-paper';
import LoadingText from '../../../components/loading-text';
import { translate } from '../../../i18n';

@withSnackbar
@withStyles(styles)
class Notifications extends React.Component {
  constructor(props) {
    super(props);
    const { fetchData, setPageTitle } = this.props;

    this.typeName = 'notification';

    setPageTitle('Notificari');
    fetchData();
  }

  getTableColumns = () => {
    return [
      {
        id: 'title',
        accessor: 'title',
        sortable: true,
        style: { fontWeight: 700 },
        label: translate('Titlu'),
      },
      {
        id: 'message',
        accessor: 'message',
        sortable: true,
        label: translate('Mesaj'),
      },
      {
        id: 'users',
        accessor: 'users',
        label: translate('Destinatari'),
        align: 'right',
        renderValue: row => (row.users && row.users.length) || '',
      },
      {
        id: 'type',
        accessor: 'type',
        label: translate('Tip'),
        sortable: true,
        align: 'center',
        renderValue: row => (row.type || '').toUpperCase(),
      },
      {
        id: 'visibility',
        accessor: 'visibility',
        label: 'Vizibilitate',
        align: 'center',
        renderValue: row => (row.visibility || '').toUpperCase(),
      },
      {
        id: 'sent_at',
        accessor: 'sent_at',
        label: 'Trimis',
        sortable: true,
        align: 'right',
        renderValue: row => (
          <>
            <Box>{new Date(row.created_at).toLocaleTimeString()}</Box>
            <Box>{new Date(row.created_at).toLocaleDateString()}</Box>
          </>
        ),
      },
    ];
  };

  getTableFilters = () => {
    const { setTableFilter, filters } = this.props;

    return [
      {
        label: 'Tip',
        type: 'select',
        value: filters.type,
        onChange: value => setTableFilter('type', value),
        options: [
          {
            value: '',
            label: 'Toate',
          },
          {
            value: 'push',
            label: 'Notificare PUSH',
          },
          {
            value: 'sms',
            label: 'SMS',
          },
          {
            value: 'log',
            label: 'Log sistem',
          },
          {
            value: 'email',
            label: 'Email',
          },
          {
            value: 'internal',
            label: 'Mesaj intern',
          },
        ],
      },
      {
        label: 'Cauta',
        type: 'search',
        value: filters.search,
        onChange: value => setTableFilter('search', value),
      },
    ];
  };

  shouldComponentRender = () => {
    const { initialLoaded } = this.props;

    return initialLoaded;
  };

  getLoadingSkeleton = () => {
    return <LoadingText />;
  };

  render() {
    const { classes, dataById } = this.props;
    const data = Object.values(dataById);

    if (!this.shouldComponentRender()) {
      return <div className={classes.root}>{this.getLoadingSkeleton()}</div>;
    }

    return (
      <div className={classes.root}>
        <Typography variant="h5" component="h2" className="mb-1">
          Notificari
        </Typography>
        <Typography className="mb-3 text-muted size14">Sunt afisate ultimele 1000 de inregistrari</Typography>
        <ContentPaper fullWidth className={classes.paper}>
          <EnhancedTable
            noCheckbox
            filters={this.getTableFilters()}
            columns={this.getTableColumns()}
            data={data}
            rowCount={data.length}
            order="desc"
            orderBy="id"
            size="small"
          />
        </ContentPaper>
      </div>
    );
  }
}

export default Notifications;
