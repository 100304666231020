import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { withStyles } from '@material-ui/styles';
import shallowEqual from '../lib/shallowEqual';

import { styles } from '../features/ui/styles/global';

@withRouter
@withStyles(styles)
class CustomStaticSwitch extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      !shallowEqual(this.props, nextProps, (value, other, key) => {
        return key === 'children';
      }) || !shallowEqual(this.state, nextState)
    );
  }

  render() {
    const { classes, children, location } = this.props;
    return (
      <TransitionGroup style={{ position: 'relative' }}>
        <CSSTransition
          key={location.key}
          classNames={{
            enter: classes.fadeTransitionEnter,
            enterActive: classes.fadeTransitionEnterActive,
            exit: classes.fadeTransitionExit,
          }}
          timeout={300}>
          <section style={{ position: 'relative', left: 0, right: 0, top: 0, bottom: 0, WebkitOverflowScrolling: 'touch' }}>
            <Switch location={location}>{children}</Switch>
          </section>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export default CustomStaticSwitch;
