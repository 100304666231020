import { buildApiClient } from './helpers';

const apiClient = buildApiClient();

function fetch() {
  return apiClient.get('channel');
}

function add(data) {
  return apiClient.post('channel', data);
}

function update(id, data) {
  return apiClient.patch(`channel/${id}`, data);
}

function getById(id) {
  return apiClient.get(`channel/${id}`);
}

function deleteById(id) {
  return apiClient.delete(`channel/${id}`);
}

export const channelApi = {
  fetch,
  add,
  update,
  getById,
  deleteById,
};
