

import AuthStore from "../lib/stores/auth-store";

export default function PrivateComponent ({component: Component, children, ...rest}) {

    return AuthStore.isLoggedIn()
        ? children
        : null;

}
