import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import snackbar from '../features/snackbar/reducers';
import ui from '../features/ui/reducers';
import users from '../features/user/reducers';
import channels from '../features/channel/reducers';
import offers from '../features/offer/reducers';
import notifications from '../features/notification/reducers';
import debuglogs from '../features/debuglog/reducers';
import system from '../features/system/reducers';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    ui,
    snackbar,
    users,
    channels,
    offers,
    notifications,
    debuglogs,
    system,
  });

export default createRootReducer;
