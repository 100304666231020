import produce from 'immer';
import { normalize, schema } from 'normalizr';

import _ from 'lodash';
import {
  NOTIFICATIONS_SET_TABLE_FILTER,
  NOTIFICATIONS_FETCH_REQUEST,
  NOTIFICATIONS_FETCH_SUCCESS,
  NOTIFICATIONS_FETCH_ERROR,
} from '../actions';

const INITIAL_STATE = {
  entities: {
    notifications: {},
  },
  entitiesIds: [],
  selected: null,

  // Filters
  filters: {
    search: null,
    type: null,
  },

  // Other state
  loading: false,
  initialLoaded: false,
  loadingId: null,
  loadingIds: [],

  fetchError: null,
  saveError: null,
};

// Define the order schema so we can normalize the data and index it properly
const notificationSchema = new schema.Entity('notifications');
const notificationListSchema = new schema.Array(notificationSchema);

export default function notifications(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case NOTIFICATIONS_FETCH_SUCCESS:
        // Normalize the data for better access
        const { data } = action.payload;
        const normalized = normalize(data, notificationListSchema);
        draft.entities = normalized.entities;
        draft.entitiesIds = normalized.result;
        draft.loading = false;
        draft.initialLoaded = true;
        draft.fetchError = null;

        break;

      case NOTIFICATIONS_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case NOTIFICATIONS_FETCH_ERROR:
        draft.loading = false;
        draft.fetchError = action.payload.error;
        break;

      case NOTIFICATIONS_SET_TABLE_FILTER:
        draft.filters[action.payload.name] = action.payload.value;
        break;

      default:
        break;
    }
  });
}

// Selectors
export function getFilteredNotifications(state) {
  const { entities, filters } = state.notifications;

  return _.filter(entities.notifications, o => {
    if (filters.search) {
      if (
        (o.title || '').toLowerCase().indexOf(filters.search.toLowerCase()) === -1 &&
        (o.message || '').toLowerCase().indexOf(filters.search.toLowerCase()) === -1
      ) {
        return false;
      }
    }

    if (filters.type && o.type !== filters.type) {
      return false;
    }

    return true;
  });
}

export const getNotificationByIdSelector = (state, id) => {
  return state.notifications && state.notifications.entities && state.notifications.entities.notifications
    ? state.notifications.entities.notifications[id]
    : {};
};
