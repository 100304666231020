import { call, put } from 'redux-saga/effects';

import { fetchNotificationsError, fetchNotificationsSuccess } from '../actions';
import { parseApiError } from '../../../api/helpers';
import { notificationApi } from '../../../api/notification';

export function* fetchNotificationsSaga() {
  try {
    const response = yield call(notificationApi.fetch);

    if (!response.ok) {
      yield put(fetchNotificationsError(parseApiError(response).message));
      return;
    }

    yield put(fetchNotificationsSuccess(response.data.data.results || []));
  } catch (err) {
    yield put(fetchNotificationsError(parseApiError(err).message));
  }
}
