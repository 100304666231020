import React from 'react';
import { Box, Link, Typography, withStyles } from '@material-ui/core';

import { withSnackbar } from 'notistack';

import styles from '../styles';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import ContentPaper from '../../../components/content-paper';
import LoadingText from '../../../components/loading-text';

@withSnackbar
@withStyles(styles)
class Debuglogs extends React.Component {
  constructor(props) {
    super(props);
    const { fetchData, setPageTitle } = this.props;

    this.typeName = 'debuglog';

    setPageTitle('Cereri suport');
    fetchData();
  }

  getTableColumns = () => {
    return [
      {
        id: 'id2',
        accessor: 'id',
        sortable: true,
        label: 'ID',
        style: {
          width: 70,
        },
      },
      {
        id: 'user',
        accessor: 'user',
        label: 'Utilizator',
        renderValue: row => {
          if (row.user_id && row.user && row.user.id) {
            return (
              <Link onClick={event => event.stopPropagation()} href={`/user/edit/${row.user.id}`}>
                {`${row.user.first_name} ${row.user.last_name}`}
              </Link>
            );
          }

          return null;
        },
      },
      {
        id: 'created_at',
        accessor: 'created_at',
        label: 'Primit la',
        sortable: true,
        align: 'right',
        renderValue: row => (
          <>
            <Box>{new Date(row.created_at).toLocaleTimeString()}</Box>
            <Box>{new Date(row.created_at).toLocaleDateString()}</Box>
          </>
        ),
      },
    ];
  };

  getTableFilters = () => {
    const { setTableFilter, filters } = this.props;

    return [];
  };

  shouldComponentRender = () => {
    const { initialLoaded } = this.props;

    return initialLoaded;
  };

  getLoadingSkeleton = () => {
    return <LoadingText />;
  };

  render() {
    const { classes, dataById } = this.props;
    const data = Object.values(dataById);

    if (!this.shouldComponentRender()) {
      return <div className={classes.root}>{this.getLoadingSkeleton()}</div>;
    }

    return (
      <div className={classes.root}>
        <Typography variant="h5" component="h2" className="mb-3">
          Cereri suport
        </Typography>
        <ContentPaper fullWidth className={classes.paper}>
          <EnhancedTable
            noCheckbox
            filters={this.getTableFilters()}
            columns={this.getTableColumns()}
            data={data}
            rowCount={data.length}
            order="desc"
            orderBy="id"
            size="small"
          />
        </ContentPaper>
      </div>
    );
  }
}

export default Debuglogs;
