import { EventEmitter } from 'events';
import { buildApiClient } from '../../api/helpers';
import { appConfig } from '../../config';

const emitter = new EventEmitter();
const apiClient = buildApiClient();

let USER_DATA = {
  username: '',
  isLoggedIn: false,
};

let IS_LOGGED_IN = 0;
let SESSION_ID = null;

const { API_TOKEN_STORAGE_NAME } = appConfig;

const AuthStore = {
  getUser() {
    return USER_DATA;
  },

  getSessionId() {
    return localStorage.getItem(API_TOKEN_STORAGE_NAME);
  },

  loginUser(sessionId, sessionUser) {
    // If not session ID, try to fetch it from local storage
    if (!sessionId) {
      sessionId = localStorage.getItem(API_TOKEN_STORAGE_NAME);
    }

    if (!sessionId) {
      return false;
    }

    // Log the user in
    USER_DATA = sessionUser;
    SESSION_ID = sessionId;
    IS_LOGGED_IN = true;

    localStorage.setItem(API_TOKEN_STORAGE_NAME, sessionId);
    localStorage.setItem('user_data', JSON.stringify(USER_DATA));
    return true;
  },

  logoutUser() {
    USER_DATA = {};
    SESSION_ID = null;
    IS_LOGGED_IN = false;

    // We delete the JWT from local storage
    localStorage.removeItem(API_TOKEN_STORAGE_NAME);
    localStorage.removeItem('user_data');

    // Let listeners know the user has logged out
    emitter.emit('logout');
  },

  isLoggedIn() {
    /* console.log('IS_LOGGED_IN', IS_LOGGED_IN)
    console.log('SESSION_ID', SESSION_ID)
    console.log('USER_DATA', USER_DATA) */
    return !!(IS_LOGGED_IN && SESSION_ID && USER_DATA);
  },

  validateToken(sessionId, callback) {
    if (!sessionId) {
      callback(null);
      return;
    }

    apiClient
      .post('auth/validate-token', {
        token: sessionId,
      })
      .then(
        res => {
          // Execute callback
          if (callback && typeof callback === 'function') {
            callback(res.data);
          }
        },
        () => {
          // Execute callback
          if (callback && typeof callback === 'function') {
            callback(null);
          }
        },
      );
  },
};

export default AuthStore;
