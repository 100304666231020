export const styles = theme => ({
  input: {
    marginBottom: theme.spacing(1),
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    [theme.breakpoints.up(540 + theme.spacing(1) * 2)]: {
      width: 540,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4, 1, 3),
    minHeight: 220,
    [theme.breakpoints.up(540)]: {
      padding: theme.spacing(4, 6, 7),
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formLogin: {
    padding: theme.spacing(0, 1),
  },
  submit: {
    marginTop: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    padding: theme.spacing(1.5, 3),
    fontSize: 16,
  },
  error: {
    color: theme.palette.error.main,
    marginTop: 0,
    marginBottom: theme.spacing(2),
    padding: '0 24px',
    textAlign: 'center',
  },
  logo: {
    padding: '20px 30px 50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  logoImg: {
    maxHeight: 50,
    marginRight: 16,
  },
  logoText: {
    fontWeight: 300,
    fontSize: '30px',
    marginTop: '-2px',
    color: theme.palette.brand,
  },
  copyright: {
    marginTop: 30,
    textAlign: 'center',
    fontSize: 14,
    color: '#a2a2a2'
  },
  questionAnswer: {
    marginTop: 8,
  },
  questionRow: {
    marginBottom: 30,
  },
  questionName: {
    fontSize: 18,
  },
  questionStar: {
    fontSize: 24,
    margin: 6,
    marginTop: 6,
    cursor: 'pointer',
    color: '#aaa',
    [theme.breakpoints.up(540)]: {
      fontSize: 32,
    },
  },
  fullStar: {
    color: '#f0d355',
  }
});
