import React from 'react';
import _ from 'lodash';
import { Button, Box, Link, Typography, withStyles } from '@material-ui/core';

import { withSnackbar } from 'notistack';
import { addSeconds, compareAsc, formatDistanceStrict } from 'date-fns';

import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import styles from '../styles';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import ContentPaper from '../../../components/content-paper';
import ActionMenu from './row-actions';
import LoadingText from '../../../components/loading-text';
import { offerApi } from '../../../api/offer';
import { parseApiError } from '../../../api/helpers';
import Icon from '../../../components/icon';

const ErrorTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'red',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

@withSnackbar
@withStyles(styles)
class Offers extends React.Component {
  refreshInterval = null;

  constructor(props) {
    super(props);
    const { fetchData, setPageTitle, type } = this.props;

    this.typeName = 'offer';

    const pageConfig = {
      title: 'Lucrari',
      showAddButton: false,
    };

    if (type !== undefined && type === 'new') {
      pageConfig.title = 'Disponibile';
      pageConfig.showAddButton = true;
    } else if (type !== undefined && type === 'completed') {
      pageConfig.title = 'Lucrari finalizate';
    } else if (type !== undefined && type === 'started') {
      pageConfig.title = 'Lucrari in desfasurare';
    } else if (type !== undefined && type === 'accepted') {
      pageConfig.title = 'Lucrari preluate';
    }

    setPageTitle(pageConfig.title);
    fetchData();

    this.state = {
      pageConfig,
    };
  }

  componentDidMount() {
    const { fetchUsers, fetchData } = this.props;
    fetchUsers();

    // Refresh every 10 seconds
    this.refreshInterval = setInterval(() => fetchData(), 10000);
  }

  componentWillUnmount() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  handleMassDelete = (ids) => ids.map((id) => this.handleDelete(id));

  handleDelete = async (id) => {
    const { enqueueSnackbar, fetchData } = this.props;

    // Delete AJAX
    try {
      const response = await offerApi.deleteById(id);
      if (!response.ok) {
        let errMessage = parseApiError(response).message;
        if (!errMessage) {
          errMessage = 'Lucrarea nu a putut fi stearsa';
        }

        enqueueSnackbar(errMessage, { options: { variant: 'error' } });
        return;
      }

      // All good
      setTimeout(() => fetchData(), 100);
    } catch (err) {
      enqueueSnackbar('Lucrarea nu a putut fi stearsa', { options: { variant: 'error' } });
    }
  };

  handleStatusChange = async (id, data) => {
    const { enqueueSnackbar, fetchData } = this.props;

    // Change status
    try {
      const response = await offerApi.update(id, { ...data });
      if (!response.ok) {
        enqueueSnackbar('Eroare la schimbarea starii', { options: { variant: 'error' } });
        return;
      }

      // All good
      setTimeout(() => fetchData(), 100);
    } catch (err) {
      enqueueSnackbar('Eroare la schimbarea starii', { options: { variant: 'error' } });
    }
  };

  handleSendPushNotification = async (id) => {
    const { enqueueSnackbar, fetchData } = this.props;

    // Change status
    try {
      const response = await offerApi.sendPushNotification(id);
      if (!response.ok) {
        let errMessage = parseApiError(response).message;
        if (!errMessage) {
          errMessage = 'N/A';
        }

        enqueueSnackbar(`Eroare la trimiterea notificarii: ${errMessage}`, { options: { variant: 'error' } });
        return;
      }

      // All good
      enqueueSnackbar(`Notificare trimisa cu succes`);
      setTimeout(() => fetchData(), 100);
    } catch (err) {
      enqueueSnackbar('Eroare la trimiterea notificarii', { options: { variant: 'error' } });
    }
  };

  handleRowClick = ({ id }) => {
    const { history } = this.props;
    history.push(`/offer/edit/${id}`);
  };

  handleAdd = () => {
    const { history } = this.props;
    history.push('/offer/add');
  };

  getTableColumns = () => {
    const { classes, loadingIds, type } = this.props;

    let systemConfig = JSON.parse(localStorage.getItem('systemConfig') || {});
    systemConfig = systemConfig || {};

    let columns = [
      {
        id: 'id2',
        accessor: 'id',
        sortable: true,
        label: 'ID',
        style: {
          width: 70,
        },
      },
      {
        id: 'client_name',
        accessor: 'client_name',
        sortable: true,
        label: 'Nume client',
      },
      {
        id: 'client_city',
        accessor: 'client_city',
        sortable: true,
        label: 'Oras',
        renderValue: ({ client_city, channel }) =>
          `${client_city || ''} (${channel && channel.name ? channel.name : '-'})`,
      },
      /* {
        id: 'client_phone',
        accessor: 'client_phone',
        sortable: true,
        label: 'Telefon client',
      }, */
      /* {
        id: 'company_name',
        accessor: 'company_name',
        label: 'Firma',
        sortable: true,
      }, */
      {
        id: 'website_order_number',
        accessor: 'website_order_number',
        label: 'Comanda',
        sortable: true,
        renderValue: (row) => {
          if (!row.website_order_number) {
            return '-';
          }

          const filter = window.btoa(`real_order_id=${row.website_order_number}`);
          const url = `https://www.spy-shop.ro/index.php/gestiune/sales_order/index/filter/${filter}`;

          return (
            <Link
              onClick={(event) => event.stopPropagation()}
              href={url}
              target="_blank">{`#${row.website_order_number}`}</Link>
          );
        },
      },
      {
        id: 'assigned_user',
        accessor: 'assigned_user',
        label: 'Instalator',
        sortable: true,
        renderValue: (row) => {
          if (row.assigned_user && row.user && row.user.id) {
            return (
              <Link onClick={(event) => event.stopPropagation()} href={`/user/edit/${row.user.id}`}>
                {`${row.user.first_name} ${row.user.last_name}`}
              </Link>
            );
          }

          return null;
        },
      },
      {
        id: 'cancel_note',
        accessor: 'cancel_note',
        label: 'Observatii',
        sortable: false,
        renderValue: (row) => {
          if (!row.cancel_note) {
            return null;
          }

          return (
            <ErrorTooltip title={row.cancel_note}>
              <Box>
                <Icon name="exclamation-triangle" solid light={false} style={{ color: 'red', fontSize: 24 }} />
              </Box>
            </ErrorTooltip>
          );
        },
      },
      {
        id: 'status',
        accessor: 'status',
        label: '',
        align: 'left',
        style: {
          width: 100,
        },
        renderValue: (row) => {
          let { status } = row;
          if (status === 'new') {
            status = 'Noua';
          } else if (status === 'completed') {
            status = 'Finalizata';
          } else if (status === 'started') {
            status = 'In desfasurare';
          } else if (status === 'accepted') {
            status = 'Preluata';
          }

          return (
            <Chip
              size="small"
              label={status}
              className={[
                classes.chip,
                row.status === 'new' && classes.chipNew,
                row.status === 'started' && classes.chipPending,
                row.status === 'accepted' && classes.chipActive,
                row.status === 'completed' && classes.chipCompleted,
              ].join(' ')}
            />
          );
        },
      },
      /* {
        id: 'suspended',
        accessor: 'suspended',
        label: 'Publicata',
        renderValue: row => {
          return <Typography color={row.suspended ? 'inherit' : 'inherit'}>{row.suspended ? 'Nu' : 'Da'}</Typography>;
        },
      }, */
      {
        id: 'suspended',
        accessor: 'suspended',
        label: '',
        align: 'left',
        style: {
          width: 100,
        },
        renderValue: (row) => {
          if (!row.suspended) {
            return null;
          }

          return <Chip size="small" variant="outlined" label="Suspendata" className={classes.chipSuspended} />;
        },
      },
      {
        id: 'created_at',
        accessor: 'created_at',
        label: 'Adaugata',
        sortable: true,
        renderValue: (row) => (
          <>
            <Box>{new Date(row.created_at).toLocaleTimeString()}</Box>
            <Box>{new Date(row.created_at).toLocaleDateString()}</Box>
          </>
        ),
      },
      {
        id: 'id',
        accessor: 'id',
        numeric: false,
        label: '',
        align: 'right',
        padding: 'none',
        renderValue: (row) => (
          <ActionMenu
            isLoading={loadingIds && loadingIds.indexOf(parseInt(row.id, 10)) !== -1}
            handleDelete={this.handleDelete}
            handleStatusChange={this.handleStatusChange}
            handleSendPushNotification={this.handleSendPushNotification}
            id={row.id}
            data={row}
          />
        ),
      },
    ];

    // Now let's alter a bit the columns based on our current view type
    if (type !== undefined && type) {
      if (type === 'started') {
        columns = [
          ...columns.slice(0, columns.length - 2),
          {
            id: 'expires_at',
            accessor: 'status_updated_at',
            label: 'Contor',
            renderValue: (row) => {
              // Is expired?

              let distance = null;
              const { ALLOWED_ACCEPTED_OFFER_REVOKE_TIMEOUT = null } = (systemConfig && systemConfig.timeouts) || {};

              if (row.status_updated_at && ALLOWED_ACCEPTED_OFFER_REVOKE_TIMEOUT) {
                const expiryDate = addSeconds(new Date(row.status_updated_at), ALLOWED_ACCEPTED_OFFER_REVOKE_TIMEOUT);

                if (compareAsc(expiryDate, new Date()) === -1) {
                  return <Chip size="small" variant="outlined" label="Expirat" className={classes.chipSuspended} />;
                }

                distance = formatDistanceStrict(expiryDate, new Date());
                if (!distance) {
                  distance = '';
                }

                distance = distance
                  .replace('days', 'zile')
                  .replace('day', 'zi')
                  .replace('hours', 'ore')
                  .replace('hour', 'ora')
                  .replace('minutes', 'minuPL')
                  .replace('minute', 'minut')
                  .replace('minuPL', 'minute')
                  .replace('seconds', 'secunde')
                  .replace('second', 'secunda');
              }

              return distance;
            },
          },
          {
            id: 'status_updated_at',
            accessor: 'status_updated_at',
            label: 'Inceputa',
            sortable: true,
            renderValue: (row) =>
              !row.status_updated_at ? null : (
                <>
                  <Box>{new Date(row.status_updated_at).toLocaleTimeString()}</Box>
                  <Box>{new Date(row.status_updated_at).toLocaleDateString()}</Box>
                </>
              ),
          },
          columns[columns.length - 2],
        ];
      }

      if (type === 'accepted') {
        columns = [
          ...columns.slice(0, columns.length - 2),
          {
            id: 'expires_at',
            accessor: 'status_updated_at',
            label: 'Contor',
            renderValue: (row) => {
              // Is expired?

              let distance = null;
              const { ALLOWED_ACCEPTED_OFFER_REVOKE_TIMEOUT = null } = (systemConfig && systemConfig.timeouts) || {};

              if (row.status_updated_at && ALLOWED_ACCEPTED_OFFER_REVOKE_TIMEOUT) {
                const expiryDate = addSeconds(new Date(row.status_updated_at), ALLOWED_ACCEPTED_OFFER_REVOKE_TIMEOUT);

                if (compareAsc(expiryDate, new Date()) === -1) {
                  return <Chip size="small" variant="outlined" label="Expirat" className={classes.chipSuspended} />;
                }

                distance = formatDistanceStrict(expiryDate, new Date());
                if (!distance) {
                  distance = '';
                }

                distance = distance
                  .replace('days', 'zile')
                  .replace('day', 'zi')
                  .replace('hours', 'ore')
                  .replace('hour', 'ora')
                  .replace('minutes', 'minuPL')
                  .replace('minute', 'minut')
                  .replace('minuPL', 'minute')
                  .replace('seconds', 'secunde')
                  .replace('second', 'secunda');
              }

              return distance;
            },
          },
          {
            id: 'status_updated_at',
            accessor: 'status_updated_at',
            label: 'Preluata',
            sortable: true,
            renderValue: (row) =>
              !row.status_updated_at ? null : (
                <>
                  <Box>{new Date(row.status_updated_at).toLocaleTimeString()}</Box>
                  <Box>{new Date(row.status_updated_at).toLocaleDateString()}</Box>
                </>
              ),
          },
          columns[columns.length - 2],
        ];
      }

      if (type === 'completed') {
        columns = [
          ...columns.slice(0, columns.length - 2),
          {
            id: 'status_updated_at',
            accessor: 'status_updated_at',
            label: 'Finalizata',
            sortable: true,
            renderValue: (row) =>
              !row.status_updated_at ? null : (
                <>
                  <Box>{new Date(row.status_updated_at).toLocaleTimeString()}</Box>
                  <Box>{new Date(row.status_updated_at).toLocaleDateString()}</Box>
                </>
              ),
          },
          columns[columns.length - 2],
        ];
      }
    }

    return columns;
  };

  getTableFilters = () => {
    const { setTableFilter, filters, users } = this.props;
    let usersFilter = _.map(users, (user) => ({ value: user.id, label: `${user.first_name} ${user.last_name}` }));
    usersFilter = _.orderBy(usersFilter, 'label');

    return [
      {
        label: 'Publicata',
        type: 'select',
        value: (filters.suspended === null ? '' : filters.suspended).toString(),
        onChange: (value) => setTableFilter('suspended', value !== '' ? parseInt(value, 10) : null),
        options: [
          {
            value: '',
            label: 'Toate',
          },
          {
            value: '0',
            label: 'Da',
          },
          {
            value: '1',
            label: 'Nu',
          },
        ],
      },
      {
        label: 'Instalator',
        type: 'select',
        value: filters.assigned_user,
        onChange: (value) => setTableFilter('assigned_user', value),
        options: [
          {
            value: '',
            label: 'Toti',
          },
          ...usersFilter,
        ],
      },
      {
        label: 'Cauta',
        type: 'search',
        value: filters.search,
        onChange: (value) => setTableFilter('search', value),
      },
    ];
  };

  shouldComponentRender = () => {
    const { initialLoaded } = this.props;

    return initialLoaded;
  };

  getLoadingSkeleton = () => {
    return <LoadingText />;
  };

  render() {
    const { classes, dataById, pageTitle, type } = this.props;

    const data = Object.values(dataById);

    if (!this.shouldComponentRender()) {
      return <div className={classes.root}>{this.getLoadingSkeleton()}</div>;
    }

    const toolbarActions =
      !type || type === 'new' ? (
        <>
          <Button variant="contained" color="secondary" className={classes.button} onClick={this.handleAdd}>
            Adauga lucrare
          </Button>
        </>
      ) : null;

    return (
      <div className={classes.root}>
        <Typography variant="h5" component="h2" className="mb-3">
          {pageTitle || 'Lucrari'}
        </Typography>
        <ContentPaper fullWidth className={classes.paper}>
          <EnhancedTable
            toolbarActions={toolbarActions}
            filters={this.getTableFilters()}
            columns={this.getTableColumns()}
            onRowClick={this.handleRowClick}
            onMassDelete={this.handleMassDelete}
            isRowActive={(row) => !row.suspended}
            data={data}
            rowCount={data.length}
            order="desc"
            orderBy="created_at"
            size="small"
          />
        </ContentPaper>
      </div>
    );
  }
}

export default Offers;
