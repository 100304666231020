import produce from 'immer';
import { normalize, schema } from 'normalizr';

import { CHANNELS_FETCH_REQUEST, CHANNELS_FETCH_SUCCESS, CHANNELS_FETCH_ERROR } from '../actions';

const INITIAL_STATE = {
  entities: {
    channels: {},
  },
  entitiesIds: [],
  selected: null,
};

// Define the order schema so we can normalize the data and index it properly
const channelSchema = new schema.Entity('channels');
const channelListSchema = new schema.Array(channelSchema);

export default function channels(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case CHANNELS_FETCH_SUCCESS:
        // Normalize the data for better access
        const { data } = action.payload;
        const normalized = normalize(data, channelListSchema);
        draft.entities = normalized.entities;
        draft.entitiesIds = normalized.result;
        draft.loading = false;
        draft.initialLoaded = true;
        draft.fetchError = null;

        break;

      case CHANNELS_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case CHANNELS_FETCH_ERROR:
        draft.fetchError = action.payload.error;
        break;

      default:
        break;
    }
  });
}

export const getChannelByIdSelector = (state, id) => {
  return state.channels && state.channels.entities && state.channels.entities.channels
    ? state.channels.entities.channels[id]
    : {};
};
