import React from 'react';
import TopBarProgress from 'react-topbar-progress-indicator';
import { connect } from 'react-redux';

TopBarProgress.config({
  barColors: {
    '0': '#f63535',
    '0.5': '#f63535', // 1b1d39
    '1.0': '#f63535',
  },
  shadowBlur: 2,
  shadowColor: '#616161',
  barThickness: 3,
});

class ProgressBar extends React.Component {
  render() {
    const { progressBarStatus } = this.props;

    if (progressBarStatus === 'OPEN') {
      return <TopBarProgress />;
    }
    return '';
  }
}

// Connect to redux store
const mapStateToProps = state => {
  return {
    progressBarStatus: state.ui.progressBarStatus,
  };
};

const HandleProgressBar = connect(mapStateToProps, null)(ProgressBar);

export default HandleProgressBar;
