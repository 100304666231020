import { connect } from 'react-redux';
import Settings from '../components/settings';
import { setPageTitle } from '../../ui/actions';
import { enqueueSnackbar } from '../../snackbar/actions';
import { fetchSystemSettings, updateSystemSetting } from '../actions';

function mapStateToProps(state) {
  return {
    data: state.system.settings || [],
    loading: state.system.loading,
    initialLoaded: state.system.initialLoaded,
    fetchError: state.system.fetchError,
  };
}

const mapDispatchToProps = {
  fetchData: fetchSystemSettings,
  setPageTitle,
  enqueueSnackbar,
  updateSystemSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
