import { makeActionCreator } from '../../../store/actions';

export const OFFERS_FETCH_REQUEST = '@offers / FETCH REQUEST';
export const OFFERS_FETCH_SUCCESS = '@offers / FETCH SUCCESS';
export const OFFERS_FETCH_ERROR = '@offers / FETCH ERROR';

export const fetchOffers = makeActionCreator(OFFERS_FETCH_REQUEST);
export const fetchOffersError = makeActionCreator(OFFERS_FETCH_ERROR, 'error');
export const fetchOffersSuccess = makeActionCreator(OFFERS_FETCH_SUCCESS, 'data');

export const OFFERS_FETCH_OFFER_REQUEST = '@offers / FETCH OFFER REQUEST';
export const OFFERS_FETCH_OFFER_ERROR = '@offers / FETCH OFFER ERROR';
export const OFFERS_FETCH_OFFER_SUCCESS = '@offers / FETCH OFFER SUCCESS';
export const fetchOfferById = makeActionCreator(OFFERS_FETCH_OFFER_REQUEST, 'id');
export const fetchOfferByIdError = makeActionCreator(OFFERS_FETCH_OFFER_ERROR, 'error');
export const fetchOfferByIdSuccess = makeActionCreator(OFFERS_FETCH_OFFER_SUCCESS, 'id', 'data');

// Table filters
export const OFFERS_SET_TABLE_FILTER = '@offers / SET TABLE FILTER';
export const setTableFilter = makeActionCreator(OFFERS_SET_TABLE_FILTER, 'name', 'value');
