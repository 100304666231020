import produce from 'immer';
import { normalize, schema } from 'normalizr';

import _ from 'lodash';
import {
  DEBUGLOGS_SET_TABLE_FILTER,
  DEBUGLOGS_FETCH_REQUEST,
  DEBUGLOGS_FETCH_SUCCESS,
  DEBUGLOGS_FETCH_ERROR,
} from '../actions';

const INITIAL_STATE = {
  entities: {
    debuglogs: {},
  },
  entitiesIds: [],
  selected: null,

  // Filters
  filters: {
    search: null,
    type: null,
  },

  // Other state
  loading: false,
  initialLoaded: false,
  loadingId: null,
  loadingIds: [],

  fetchError: null,
  saveError: null,
};

// Define the order schema so we can normalize the data and index it properly
const debuglogSchema = new schema.Entity('debuglogs');
const debuglogListSchema = new schema.Array(debuglogSchema);

export default function debuglogs(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case DEBUGLOGS_FETCH_SUCCESS:
        // Normalize the data for better access
        const { data } = action.payload;
        const normalized = normalize(data, debuglogListSchema);
        draft.entities = normalized.entities;
        draft.entitiesIds = normalized.result;
        draft.loading = false;
        draft.initialLoaded = true;
        draft.fetchError = null;

        break;

      case DEBUGLOGS_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case DEBUGLOGS_FETCH_ERROR:
        draft.loading = false;
        draft.fetchError = action.payload.error;
        break;

      case DEBUGLOGS_SET_TABLE_FILTER:
        draft.filters[action.payload.name] = action.payload.value;
        break;

      default:
        break;
    }
  });
}

// Selectors
export function getFilteredDebuglogs(state) {
  const { entities, filters } = state.debuglogs;

  return _.filter(entities.debuglogs, o => {
    if (filters.search) {
      if (
        (o.title || '').toLowerCase().indexOf(filters.search.toLowerCase()) === -1 &&
        (o.message || '').toLowerCase().indexOf(filters.search.toLowerCase()) === -1
      ) {
        return false;
      }
    }

    if (filters.type && o.type !== filters.type) {
      return false;
    }

    return true;
  });
}

export const getDebuglogByIdSelector = (state, id) => {
  return state.debuglogs && state.debuglogs.entities && state.debuglogs.entities.debuglogs
    ? state.debuglogs.entities.debuglogs[id]
    : {};
};
