import React from 'react';
import { Button, TextField, Typography, withStyles } from '@material-ui/core';

import { withSnackbar } from 'notistack';

import styles from '../styles';
import ContentPaper from '../../../components/content-paper';
import LoadingText from '../../../components/loading-text';
import { FormRow } from '../../../components/forms/row';
import { FormContainer } from '../../../components/forms/container';
import { parseApiError } from '../../../api/helpers';
import { systemApi } from '../../../api/system';
import Icon from '../../../components/icon';

@withSnackbar
@withStyles(styles)
class Settings extends React.Component {
  constructor(props) {
    super(props);
    const { fetchData, setPageTitle } = this.props;

    this.typeName = 'system';

    setPageTitle('Configurare sistem');
    fetchData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  handleChange = (name, value) => event => {
    const { updateSystemSetting } = this.props;

    value = typeof value !== 'undefined' ? value : event.target.value;
    updateSystemSetting(name, value);
  };

  handleSubmit = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    const { data, enqueueSnackbar } = this.props;

    try {
      const response = await systemApi.update(data);

      if (!response.ok) {
        enqueueSnackbar(parseApiError(response).message, { options: { variant: 'error' } });
        return;
      }

      this.setState({ hasUnsavedChanges: false });
      enqueueSnackbar('Configurare sistem salvata');

    } catch (e) {
      enqueueSnackbar('A aparut o eroare la salvarea setarilor', { options: { variant: 'error' } });
    }
  };

  shouldComponentRender = () => {
    const { initialLoaded } = this.props;

    return initialLoaded;
  };

  getLoadingSkeleton = () => {
    return <LoadingText />;
  };

  render() {
    const { classes, data } = this.props;

    if (!this.shouldComponentRender()) {
      return <div className={classes.root}>{this.getLoadingSkeleton()}</div>;
    }

    return (
      <div className={classes.root}>
        <Typography variant="h5" component="h2" className="mb-1">
          Configurare sistem
        </Typography>
        <ContentPaper className="FormBoxPaper mb-3">
          <Typography className={classes.danger}>
            <Icon name="exclamation-triangle" style={{ marginRight: 10 }} /> Nu modifica daca nu sti ce faci!
          </Typography>
          <FormContainer>
          {data.map(setting => {

            return (
                <FormRow key={setting.name}>
                  <TextField
                    required
                    fullWidth
                    name={setting.name}
                    label={setting.label}
                    className={classes.textField}
                    value={setting.value}
                    helperText={setting.description}
                    onChange={this.handleChange(setting.name)}
                  />
                </FormRow>
            )
          })}

          <FormRow>
            <Button variant="contained" color="secondary" onClick={this.handleSubmit}>
              Salveaza
            </Button>
          </FormRow>
          </FormContainer>
        </ContentPaper>
      </div>
    );
  }
}

export default Settings;
