function timeSince(date) {
  const now = new Date();
  const secondsPast = Math.floor((now - date) / 1000);

  if (secondsPast < 60) {
    return 'online';
  }
  if (secondsPast < 3600) {
    const minutes = Math.floor(secondsPast / 60);
    return `acum ${minutes} minut${minutes > 1 ? 'e' : ''}`;
  }
  if (secondsPast < 86400) {
    const hours = Math.floor(secondsPast / 3600);
    return `acum ${hours > 1 ? hours : 'o'} ${hours > 1 ? 'de' : ''} or${hours > 1 ? 'e' : 'a'}`;
  }
  if (secondsPast < 604800) {
    // 7 days
    const days = Math.floor(secondsPast / 86400);
    return `acum ${days > 1 ? days : 'o'} zi${days > 1 ? 'le' : ''}`;
  }

  // If more than 7 days ago, return formatted date
  return date.toLocaleDateString();
}

export default timeSince;
