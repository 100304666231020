import { connect } from 'react-redux';
import Debuglogs from '../components/debuglogs';
import { setPageTitle } from '../../ui/actions';
import { enqueueSnackbar } from '../../snackbar/actions';
import { fetchDebuglogs, setTableFilter } from '../actions';
import { getFilteredDebuglogs } from '../reducers';

function mapStateToProps(state) {
  const debuglogs = getFilteredDebuglogs(state);

  return {
    dataById: debuglogs || {},
    data: state.debuglogs.entitiesIds || [],
    filters: state.debuglogs.filters,
    selected: state.debuglogs.selected,
    loading: state.debuglogs.loading,
    initialLoaded: state.debuglogs.initialLoaded,
    fetchError: state.debuglogs.fetchError,
    loadingIds: state.debuglogs.loadingIds,
  };
}

const mapDispatchToProps = {
  fetchData: fetchDebuglogs,
  setPageTitle,
  setTableFilter,
  enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Debuglogs);
