import { connect } from 'react-redux';
import Ratings from '../components/ratings';
import { fetchOffers, setTableFilter } from '../actions';
import { setPageTitle } from '../../ui/actions';
import { getFilteredOffers } from '../reducers';
import { enqueueSnackbar } from '../../snackbar/actions';

function mapStateToProps(state, ownProps) {
  let status = 'completed';

  const offers = getFilteredOffers(state, status, true);

  return {
    dataById: offers || {},
    data: state.offers.entitiesIds || [],
    filters: state.offers.filters,
    selected: state.offers.selected,
    loading: state.offers.loading,
    initialLoaded: state.offers.initialLoaded,
    fetchError: state.offers.fetchError,
    loadingIds: state.offers.loadingIds,
    pageTitle: state.ui.pageTitle,
  };
}

const mapDispatchToProps = {
  fetchData: fetchOffers,
  setPageTitle,
  setTableFilter,
  enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ratings);
