import React from 'react';
import { Button, Link, Typography, withStyles } from '@material-ui/core';

import { withSnackbar } from 'notistack';

import Chip from '@material-ui/core/Chip';
import styles from '../styles';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import ContentPaper from '../../../components/content-paper';
import ActionMenu from './row-actions';
import LoadingText from '../../../components/loading-text';
import { translate } from '../../../i18n';
import { userApi } from '../../../api/user';
import * as roles from '../../../roles';
import { parseApiError } from '../../../api/helpers';
import timeSince from '../../../lib/timeSince';

@withSnackbar
@withStyles(styles)
class Users extends React.Component {
  constructor(props) {
    super(props);
    const { fetchData, fetchChannels, setTableFilter, setPageTitle, status } = this.props;

    this.typeName = 'user';

    setPageTitle('Utilizatori');
    fetchData();
    fetchChannels();

    // Enforce filters?
    if (status !== undefined && status === 'pending') {
      setTableFilter('status', 'pending');
    } else {
      setTableFilter('status', 'active');
    }
  }

  handleMassDelete = ids => ids.map(id => this.handleDelete(id));

  handleDelete = async id => {
    const { enqueueSnackbar, fetchData } = this.props;

    // Delete AJAX
    try {
      const response = await userApi.deleteById(id);
      if (!response.ok) {
        let errMessage = parseApiError(response).message;
        if (!errMessage) {
          errMessage = 'Utilizatorul nu a putut fi sters';
        }

        enqueueSnackbar(errMessage, { options: { variant: 'error' } });
        return;
      }

      // All good
      setTimeout(() => fetchData(), 100);
    } catch (err) {
      enqueueSnackbar('Utilizatorul nu a putut fi sters', { options: { variant: 'error' } });
    }
  };

  handleStatusChange = async (id, status) => {
    const { enqueueSnackbar, fetchData } = this.props;

    // Change status
    try {
      const response = await userApi.update(id, { status });
      if (!response.ok) {
        enqueueSnackbar('Eroare la schimbarea starii', { options: { variant: 'error' } });
        return;
      }

      // All good
      setTimeout(() => fetchData(), 100);
    } catch (err) {
      enqueueSnackbar('Eroare la schimbarea starii', { options: { variant: 'error' } });
    }
  };

  handleRowClick = ({ id }) => {
    const { history } = this.props;
    history.push(`/user/edit/${id}`);
  };

  handleAdd = () => {
    const { history } = this.props;
    history.push('/user/add');
  };

  getTableColumns = () => {
    const { classes, loadingIds } = this.props;

    return [
      {
        id: 'first_name',
        accessor: 'first_name',
        sortable: true,
        label: translate('Nume'),
      },
      {
        id: 'last_name',
        accessor: 'last_name',
        sortable: true,
        label: translate('Prenume'),
      },
      {
        id: 'email_address',
        accessor: 'email_address',
        label: translate('Adresa email'),
        sortable: true,
      },
      {
        id: 'offers.started',
        accessor: 'offers',
        sortable: true,
        label: translate('Preluate'),
        renderValue: row => (row.offers && row.offers.started ? row.offers.started : ''),
      },
      {
        id: 'offers.completed',
        accessor: 'offers',
        sortable: true,
        label: translate('Finalizate'),
        renderValue: row => (row.offers && row.offers.completed ? row.offers.completed : ''),
      },
      {
        id: 'magento_user_id',
        accessor: 'magento_user_id',
        label: translate('Comenzi Magento'),
        renderValue: row => {
          if (!row.magento_user_id) {
            return '-';
          }

          const url = `https://www.spy-shop.ro/index.php/gestiune/customer/edit/id/${row.magento_user_id}/?active_tab=orders&skipCsrf=12e616777573feceeac5b88bf373557a`;

          return (
            <Link onClick={event => event.stopPropagation()} href={url} target="_blank">
              Vezi comenzi
            </Link>
          );
        },
      },
      {
        id: 'last_connected_at',
        accessor: 'last_connected_at',
        sortable: true,
        label: translate('Ultima conectare'),
        renderValue: row => (!row.last_connected_at ? null : timeSince(new Date(row.last_connected_at))),
      },
      {
        id: 'role',
        accessor: 'role',
        label: 'Tip utilizator',
        renderValue: row => roles.ROLE_LABELS[row.role],
      },
      {
        id: 'status',
        accessor: 'status',
        label: '',
        renderValue: row => {
          let { status } = row;
          if (status === 'active') {
            status = 'Activ';
          } else if (status === 'inactive') {
            status = 'Suspendat';
          } else if (status === 'pending') {
            status = 'Utilizatori noi';
          }

          return (
            <Chip
              size="small"
              label={status}
              className={[
                classes.chip,
                row.status === 'active' && classes.chipActive,
                row.status === 'pending' && classes.chipPending,
              ].join(' ')}
            />
          );
        },
      },
      {
        id: 'id',
        accessor: 'id',
        numeric: false,
        label: '',
        align: 'right',
        padding: 'none',
        renderValue: row => (
          <ActionMenu
            isLoading={loadingIds && loadingIds.indexOf(parseInt(row.id, 10)) !== -1}
            handleDelete={this.handleDelete}
            handleStatusChange={this.handleStatusChange}
            id={row.id}
            data={row}
          />
        ),
      },
    ];
  };

  getTableFilters = () => {
    const { channels, setTableFilter, filters } = this.props;

    return [
      {
        label: 'Judet',
        type: 'select',
        value: filters.channel,
        onChange: value => setTableFilter('channel', value),
        options: [
          {
            value: '',
            label: 'Toate',
          },
          ...channels.map(c => ({ value: c.id, label: c.name })),
        ],
      },
      {
        label: 'Rol',
        type: 'select',
        value: filters.role,
        onChange: value => setTableFilter('role', value),
        options: [
          {
            value: '',
            label: 'Toate',
          },
          {
            value: 'admin',
            label: 'Administrator',
          },
          {
            value: 'manager',
            label: 'Account Manager',
          },
          {
            value: 'user',
            label: 'Instalator',
          },
        ],
      },
      {
        label: 'Stare',
        type: 'select',
        value: filters.status,
        onChange: value => setTableFilter('status', value),
        options: [
          {
            value: '',
            label: 'Toate',
          },
          {
            value: 'active',
            label: 'Activ',
          },
          {
            value: 'pending',
            label: 'Utilizatori noi',
          },
          {
            value: 'inactive',
            label: 'Inactiv',
          },
        ],
      },
      {
        label: 'Cauta',
        type: 'search',
        value: filters.search,
        onChange: value => setTableFilter('search', value),
      },
    ];
  };

  shouldComponentRender = () => {
    const { initialLoaded } = this.props;

    return initialLoaded;
  };

  getLoadingSkeleton = () => {
    return <LoadingText />;
  };

  render() {
    const { classes, dataById } = this.props;
    const data = Object.values(dataById);

    if (!this.shouldComponentRender()) {
      return <div className={classes.root}>{this.getLoadingSkeleton()}</div>;
    }

    const toolbarActions = (
      <>
        <Button variant="contained" color="secondary" className={classes.button} onClick={this.handleAdd}>
          Adauga utilizator
        </Button>
      </>
    );

    return (
      <div className={classes.root}>
        <Typography variant="h5" component="h2" className="mb-3">
          Utilizatori
        </Typography>
        <ContentPaper fullWidth className={classes.paper}>
          <EnhancedTable
            toolbarActions={toolbarActions}
            filters={this.getTableFilters()}
            columns={this.getTableColumns()}
            onRowClick={this.handleRowClick}
            onMassDelete={this.handleMassDelete}
            data={data}
            rowCount={data.length}
            order="asc"
            orderBy="name"
            size="small"
          />
        </ContentPaper>
      </div>
    );
  }
}

export default Users;
