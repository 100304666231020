import { create } from 'apisauce';
import { appConfig } from '../config';

export const buildApiClient = (addAuthHeader = true) => {
  const apiClient = create({
    baseURL: appConfig.API_BASE,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Cache-Control': 'no-cache,no-index,must-revalidate,max-age=-1',
    },
    timeout: 10 * 1000,
  });

  apiClient.addAsyncRequestTransform(request => async () => {
    // Add token auth
    if (addAuthHeader) {
      request.headers.Authorization = await localStorage.getItem(appConfig.API_TOKEN_STORAGE_NAME);
    }
  });

  return apiClient;
};

export function parseApiError(response, defaultError = 'API communication error') {
  let parsedError = {
    message: defaultError,
  };

  if (response) {
    if (response.data && response.data.error) {
      parsedError = {
        ...parsedError,
        message: response.data.error,
      };
    }

    if (response.data && response.data.errors) {
      parsedError = {
        ...parsedError,
        message: response.data.errors.join('\n'),
      };
    }
  }

  return parsedError;
}
