import { call, put } from 'redux-saga/effects';

import { fetchSystemSettingsError, fetchSystemSettingsSuccess } from '../actions';
import { parseApiError } from '../../../api/helpers';
import { systemApi } from '../../../api/system';

export function* fetchSystemSettingsSaga() {
  try {
    const response = yield call(systemApi.fetch);

    if (!response.ok) {
      yield put(fetchSystemSettingsError(parseApiError(response).message));
      return;
    }

    yield put(fetchSystemSettingsSuccess(response.data.data.results || []));
  } catch (err) {
    yield put(fetchSystemSettingsError(parseApiError(err).message));
  }
}
