import { connect } from 'react-redux';
import Notifications from '../components/notifications';
import { setPageTitle } from '../../ui/actions';
import { enqueueSnackbar } from '../../snackbar/actions';
import { fetchNotifications, setTableFilter } from '../actions';
import { getFilteredNotifications } from '../reducers';

function mapStateToProps(state) {
  const notifications = getFilteredNotifications(state);

  return {
    dataById: notifications || {},
    data: state.notifications.entitiesIds || [],
    filters: state.notifications.filters,
    selected: state.notifications.selected,
    loading: state.notifications.loading,
    initialLoaded: state.notifications.initialLoaded,
    fetchError: state.notifications.fetchError,
    loadingIds: state.notifications.loadingIds,
  };
}

const mapDispatchToProps = {
  fetchData: fetchNotifications,
  setPageTitle,
  setTableFilter,
  enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
