import { Route } from 'react-router';
import React from 'react';
import PrivateRoute from './components/private-route';
import AdminRoute from './components/admin-route';
import LoginScreen from './features/auth/login';
import LogoutScreen from './features/auth/logout';
import Dashboard from './features/dashboard/home';
import UserForm from './features/user/containers/user-form';
import Users from './features/user/containers/users';
import Offers from './features/offer/containers/offers';
import OfferForm from './features/offer/containers/offer-form';
import Notifications from './features/notification/containers/notifications';
import Debuglogs from './features/debuglog/containers/debuglogs';
import OfferReviewAddPublicContainer from './features/offer/containers/offer-review-add-public';
import Ratings from './features/offer/containers/ratings';
import Settings from './features/system/containers/settings';
import SendNotification from './features/notification/containers/send';

export const routes = [
  {
    path: '/login',
    key: 'login-route',
    component: LoginScreen,
    RouteComponent: Route,
  },
  {
    path: '/logout',
    key: 'logout-route',
    component: LogoutScreen,
    RouteComponent: Route,
  },
  {
    path: '/forgot-password',
    key: 'forgot-password-route',
    component: LoginScreen,
    RouteComponent: Route,
  },
  {
    path: '/adauga-review-lucrare/guid/:guid',
    key: 'adauga-review-lucrare-route',
    component: props => <OfferReviewAddPublicContainer guid={props.match.params.guid} {...props} />,
    RouteComponent: Route,
  },
  {
    path: '/dashboard',
    key: 'dashboard-route',
    exact: true,
    component: Dashboard,
    RouteComponent: PrivateRoute,
  },
  {
    path: '/user/add',
    key: 'user-add-route',
    exact: true,
    component: props => <UserForm {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/user/edit/:id',
    key: 'user-edit-route',
    exact: true,
    component: props => <UserForm id={props.match.params.id} {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/user',
    key: 'user-list-route',
    exact: true,
    component: Users,
    RouteComponent: AdminRoute,
  },
  {
    path: '/user/pending',
    key: 'user-pending-route',
    exact: true,
    component: props => <Users {...props} status="pending" />,
    RouteComponent: AdminRoute,
  },

  // Offers
  {
    path: '/offer',
    key: 'offer-list-route',
    exact: true,
    component: Offers,
    RouteComponent: AdminRoute,
  },
  {
    path: '/offer/new',
    key: 'offer-list-route',
    exact: true,
    component: props => <Offers {...props} type="new" />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/offer/started',
    key: 'offer-list-route',
    exact: true,
    component: props => <Offers {...props} type="started" />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/offer/accepted',
    key: 'offer-accepted-route',
    exact: true,
    component: props => <Offers {...props} type="accepted" />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/offer/completed',
    key: 'offer-list-route',
    exact: true,
    component: props => <Offers {...props} type="completed" />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/offer/add',
    key: 'offer-add-route',
    exact: true,
    component: props => <OfferForm {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/offer/edit/:id',
    key: 'offer-edit-route',
    exact: true,
    component: props => <OfferForm id={props.match.params.id} {...props} />,
    RouteComponent: AdminRoute,
  },

  {
    path: '/notification',
    key: 'notifications-list-route',
    exact: true,
    component: Notifications,
    RouteComponent: PrivateRoute,
  },
  {
    path: '/notification/send',
    key: 'notifications-send-route',
    exact: true,
    component: props => <SendNotification {...props} />,
    RouteComponent: AdminRoute,
  },

  {
    path: '/debug-log',
    key: 'debug-log-list-route',
    exact: true,
    component: Debuglogs,
    RouteComponent: PrivateRoute,
  },
  {
    path: '/ratings',
    key: 'offer-ratings-route',
    exact: true,
    component: () => <Ratings />,
    RouteComponent: AdminRoute,
  },

  {
    path: '/system-settings',
    key: 'system-settings-route',
    exact: true,
    component: Settings,
    RouteComponent: AdminRoute,
  },
];
