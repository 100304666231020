import { makeActionCreator } from '../../../store/actions';

export const SYSTEM_SETTINGS_FETCH_REQUEST = '@system / FETCH REQUEST';
export const SYSTEM_SETTINGS_FETCH_SUCCESS = '@system / FETCH SUCCESS';
export const SYSTEM_SETTINGS_FETCH_ERROR = '@system / FETCH ERROR';
export const SYSTEM_SETTINGS_UPDATE_SETTING = '@system / UPDATE SETTING';

export const fetchSystemSettings = makeActionCreator(SYSTEM_SETTINGS_FETCH_REQUEST);
export const fetchSystemSettingsError = makeActionCreator(SYSTEM_SETTINGS_FETCH_ERROR, 'error');
export const fetchSystemSettingsSuccess = makeActionCreator(SYSTEM_SETTINGS_FETCH_SUCCESS, 'data');

export const updateSystemSetting = makeActionCreator(SYSTEM_SETTINGS_UPDATE_SETTING, 'name', 'value');
