import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import createRootReducer from '../reducers';
import rootSaga from '../sagas';

// Set & apply middlewares
const middlewares = [];
const enhancers = [];

// Configure history middleware
const history = createBrowserHistory();
middlewares.push(routerMiddleware(history));

// redux-thunk middleware
middlewares.push(thunk);

// Init sagas middleware
const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

// Development
const composeEnhancers =
  process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

// Create index
enhancers.push(applyMiddleware(...middlewares));
const store = createStore(createRootReducer(history), composeEnhancers(...enhancers));

// Kick off the root saga
sagaMiddleware.run(rootSaga);

export { store, history };
