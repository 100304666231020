import { buildApiClient } from './helpers';

const apiClient = buildApiClient();

function fetch() {
  return apiClient.get('system-settings');
}

function update(settings) {
  return apiClient.post('system-settings', settings);
}

export const systemApi = {
  fetch,
  update,
};
