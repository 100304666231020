import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { differenceBy, intersectionBy } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    /* margin: 'auto', */
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 330,
    height: 350,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function UserChannels({ channels, onChange, selected }) {
  const classes = useStyles();

  const left = differenceBy(channels, selected, channel => channel.id);
  const right = intersectionBy(channels, selected, channel => channel.id);

  const [checked, setChecked] = React.useState([]);
  const leftChecked = intersectionBy(left, checked, c => c.id);
  const rightChecked = intersectionBy(right, checked, c => c.id);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    /* setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked)); */
    onChange(not(left, leftChecked), right.concat(leftChecked));

    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    /* setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked)); */
    onChange(left.concat(rightChecked), not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'toate regiunile selectate' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selectate`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map(value => {
          const labelId = `transfer-list-all-item-${value.id}-label`;

          return (
            <ListItem key={labelId} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.findIndex(c => c.id === value.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.name} (${value.code})`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justify="flex-start" alignItems="center" className={classes.root}>
      <Grid item>{customList('Disponibile', left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="adauga utilizator la regiune">
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="sterge regiunea de la utilizator">
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList('Alocate', right)}</Grid>
    </Grid>
  );
}
