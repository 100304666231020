import { all, takeLatest } from 'redux-saga/effects';

import { USERS_FETCH_REQUEST, USERS_FETCH_USER_REQUEST } from '../features/user/actions';
import { fetchUserByIdSaga, fetchUsersSaga } from '../features/user/sagas';
import { CHANNELS_FETCH_REQUEST } from '../features/channel/actions';
import { fetchChannelsSaga } from '../features/channel/sagas';
import { OFFERS_FETCH_REQUEST } from '../features/offer/actions';
import { fetchOffersSaga } from '../features/offer/sagas';
import { NOTIFICATIONS_FETCH_REQUEST } from '../features/notification/actions';
import { fetchNotificationsSaga } from '../features/notification/sagas';
import { DEBUGLOGS_FETCH_REQUEST } from '../features/debuglog/actions';
import { fetchDebuglogsSaga } from '../features/debuglog/sagas';
import { SYSTEM_SETTINGS_FETCH_REQUEST } from '../features/system/actions';
import { fetchSystemSettingsSaga } from '../features/system/sagas';

export default function* rootSaga() {
  yield all([
    // Offers
    takeLatest(USERS_FETCH_REQUEST, fetchUsersSaga),
    takeLatest(USERS_FETCH_USER_REQUEST, fetchUserByIdSaga),

    // Channels
    takeLatest(CHANNELS_FETCH_REQUEST, fetchChannelsSaga),

    // Offers
    takeLatest(OFFERS_FETCH_REQUEST, fetchOffersSaga),

    // Notifications
    takeLatest(NOTIFICATIONS_FETCH_REQUEST, fetchNotificationsSaga),

    // Debug logs
    takeLatest(DEBUGLOGS_FETCH_REQUEST, fetchDebuglogsSaga),

    // System
    takeLatest(SYSTEM_SETTINGS_FETCH_REQUEST, fetchSystemSettingsSaga),
  ]);
}
