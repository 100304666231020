module.exports = {
  USER: 'user',
  MANAGER: 'manager',
  ADMIN: 'admin',

  ROLE_LABELS: {
    user: 'Instalator',
    admin: 'Administrator',
    manager: 'Account Manager',
  },
};
