import { green, orange } from '@material-ui/core/colors';

const styles = theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  expandedRow: {
    background: '#fafafa',
  },

  expandedColumn: {},

  priceText: {
    color: '#989898',
  },

  sizeItem: {
    margin: '3px 0',
  },

  searchInput: {
    padding: '14px',
    background: '#ffffff',
  },
  chip: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  chipActive: {
    backgroundColor: green[600],
    color: '#ffffff',
  },
  chipPending: {
    backgroundColor: theme.palette.warning.main,
    color: '#ffffff',
  },
});

export default styles;
