import produce from 'immer';
import { denormalize, normalize, schema } from 'normalizr';

import _ from 'lodash';
import {
  OFFERS_FETCH_REQUEST,
  OFFERS_FETCH_SUCCESS,
  OFFERS_FETCH_ERROR,
  OFFERS_FETCH_OFFER_SUCCESS,
  OFFERS_SET_TABLE_FILTER,
} from '../actions';

const INITIAL_STATE = {
  entities: {
    offers: {},
  },
  entitiesIds: [],
  selected: null,

  // Filters
  filters: {
    search: null,
    assigned_user: null,
    suspended: null,
    rating_score: false,
  },
};

// Define the order schema so we can normalize the data and index it properly
const offerSchema = new schema.Entity('offers');
const offerListSchema = new schema.Array(offerSchema);

export default function offers(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case OFFERS_SET_TABLE_FILTER:
        draft.filters[action.payload.name] = action.payload.value;
        break;

      case OFFERS_FETCH_SUCCESS:
        // Normalize the data for better access
        const { data } = action.payload;
        const normalized = normalize(data, offerListSchema);
        draft.entities = normalized.entities;
        draft.entitiesIds = normalized.result;
        draft.loading = false;
        draft.initialLoaded = true;
        draft.fetchError = null;

        break;

      case OFFERS_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case OFFERS_FETCH_ERROR:
        draft.fetchError = action.payload.error;
        break;

      case OFFERS_FETCH_OFFER_SUCCESS:
        let offersArr = denormalize(state.entitiesIds, offerListSchema, state.entities);
        const rowIndex = state.entitiesIds.indexOf(parseInt(action.payload.id, 10));

        if (rowIndex === -1) {
          offersArr = [...offersArr, action.payload.data];
        } else {
          offersArr = [...offersArr.slice(0, rowIndex), action.payload.data, ...offersArr.slice(rowIndex + 1)];
        }

        const offersUpdated = normalize(offersArr, offerListSchema);
        draft.entities = offersUpdated.entities;
        draft.entitiesIds = offersUpdated.result;
        break;

      default:
        break;
    }
  });
}

// Selectors
export function getFilteredOffers(state, forceStatus = null, withRatingScore = false) {
  const { entities, filters } = state.offers;

  return _.filter(entities.offers, o => {
    if (forceStatus && o.status !== forceStatus) {
      return false;
    }
    if (withRatingScore && !o.rating_score) {
      return false;
    }

    if (filters.search) {
      if (
        o.client_name.toLowerCase().indexOf(filters.search.toLowerCase()) === -1 &&
        (o.client_phone || '').toLowerCase().indexOf(filters.search.toLowerCase()) === -1 &&
        (o.company_name || '').toLowerCase().indexOf(filters.search.toLowerCase()) === -1 &&
        (o.website_order_number || '').toLowerCase().indexOf(filters.search.toLowerCase()) === -1
      ) {
        return false;
      }
    }

    if (filters.assigned_user) {
      if (parseInt(o.assigned_user, 10) !== parseInt(filters.assigned_user, 10)) {
        return false;
      }
    }

    if (filters.suspended !== undefined && filters.suspended !== null) {
      if (o.suspended !== filters.suspended) {
        return false;
      }
    }

    if (filters.rating_score) {
      if (!o.rating_score) {
        return false;
      }
    }

    return true;
  });
}

export const getOfferByIdSelector = (state, id) => {
  return state.offers && state.offers.entities && state.offers.entities.offers ? state.offers.entities.offers[id] : {};
};
