import { buildApiClient } from './helpers';

const apiClient = buildApiClient();

function fetch() {
  return apiClient.get('notification');
}

function send(data) {
  return apiClient.post('notification/send', data);
}

export const notificationApi = {
  fetch,
  send,
};
