import React from 'react';
import { Box, Button } from '@material-ui/core';
import clsx from 'clsx';
import { withTheme } from '@material-ui/core/styles';
import ContentPaper from './content-paper';
import ConfirmationDialog from './confirmation-dialog';

@withTheme
class FormActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteConfirmation: false,
    };
  }

  cancelDelete = () => {
    this.setState({ deleteConfirmation: false });
  };

  confirmDelete = () => {
    this.setState({ deleteConfirmation: true });
  };

  handleDelete = () => {
    const { onDelete } = this.props;

    onDelete();
  };

  render() {
    const { deleteConfirmation } = this.state;
    const {
      disableSubmit = false,
      onCancel,
      onDelete,
      onSubmit,
      position = 'top',
      short,
      theme,
      saveLabel = 'Salveaza',
    } = this.props;

    return (
      <ContentPaper
        short={!!short}
        className={clsx(['ButtonsBoxPaper', position === 'top' && 'PositionTop'])}
        elevation={0}>
        <Box className="d-flex justify-content-end align-items-center ">
          {!onDelete ? null : (
            <Button size="large" onClick={this.confirmDelete} style={{ marginRight: theme.spacing(6) }}>
              Sterge
            </Button>
          )}
          <Button size="large" onClick={onCancel} style={{ marginRight: 16 }}>
            Inapoi
          </Button>

          <Button variant="contained" color="secondary" onClick={onSubmit} disabled={disableSubmit}>
            {saveLabel}
          </Button>

          {!deleteConfirmation ? null : (
            <ConfirmationDialog
              title="Confirmare stergere"
              message="Datele nu vor putea fi recuperate. Doresti sa continui?"
              confirm={this.handleDelete}
              cancel={this.cancelDelete}
              show
            />
          )}
        </Box>
      </ContentPaper>
    );
  }
}

export default FormActions;
