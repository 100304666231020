import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import AuthStore from './lib/stores/auth-store';

// Close the drawer on refresh
// localStorage.setItem('isDrawerOpen', '0');

// Automatically log the user in if there is a session ID stored
const sessionId = AuthStore.getSessionId();

// Do we have a valid session?
AuthStore.validateToken(sessionId, res => {
  if (!res || !res.user || !res.user.username) {
    AuthStore.logoutUser();
  } else {
    AuthStore.loginUser(sessionId, res.user);

  }

  // System config?
  if (res && res.systemConfig) {
    localStorage.setItem('systemConfig', JSON.stringify(res.systemConfig));
  }

  localStorage.setItem('timezone', res && res.user ? res.user.timezone_name : 'Europe/Bucharest');

  ReactDOM.render(<App />, document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
