import { buildApiClient } from './helpers';

const apiClient = buildApiClient();

function fetch() {
  return apiClient.get('debug-log');
}

export const debuglogApi = {
  fetch,
};
