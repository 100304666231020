import React from 'react';
import { withTheme } from '@material-ui/styles';
import { Box } from '@material-ui/core';

class Dashboard extends React.Component {
  render() {
    return <Box px={3} />;
  }
}

export default withTheme(Dashboard);
