import { makeActionCreator } from '../../../store/actions';

export const DEBUGLOGS_FETCH_REQUEST = '@debuglogs / FETCH REQUEST';
export const DEBUGLOGS_FETCH_SUCCESS = '@debuglogs / FETCH SUCCESS';
export const DEBUGLOGS_FETCH_ERROR = '@debuglogs / FETCH ERROR';
export const DEBUGLOGS_SET_TABLE_FILTER = '@debuglogs / SET TABLE FILTER';

export const fetchDebuglogs = makeActionCreator(DEBUGLOGS_FETCH_REQUEST);
export const fetchDebuglogsError = makeActionCreator(DEBUGLOGS_FETCH_ERROR, 'error');
export const fetchDebuglogsSuccess = makeActionCreator(DEBUGLOGS_FETCH_SUCCESS, 'data');

// Table filters
export const setTableFilter = makeActionCreator(DEBUGLOGS_SET_TABLE_FILTER, 'name', 'value');
