import { connect } from 'react-redux';
import Offers from '../components/offers';
import { fetchOffers, setTableFilter } from '../actions';
import { setPageTitle } from '../../ui/actions';
import { getFilteredOffers } from '../reducers';
import { enqueueSnackbar } from '../../snackbar/actions';
import { fetchUsers } from '../../user/actions';

function mapStateToProps(state, ownProps) {
  let status = null;
  if (ownProps.type && typeof ownProps.type === 'string') {
    status = ownProps.type;
  }

  const offers = getFilteredOffers(state, status);

  return {
    dataById: offers || {},
    data: state.offers.entitiesIds || [],
    filters: state.offers.filters,
    selected: state.offers.selected,
    loading: state.offers.loading,
    initialLoaded: state.offers.initialLoaded,
    fetchError: state.offers.fetchError,
    loadingIds: state.offers.loadingIds,
    pageTitle: state.ui.pageTitle,
    users: state.users.entities.users || {},
  };
}

const mapDispatchToProps = {
  fetchData: fetchOffers,
  fetchUsers,
  setPageTitle,
  setTableFilter,
  enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
