import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import { Tooltip } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import theme from '../theme/theme';

const useStyles = makeStyles(() => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    letterSpacing: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
    borderRadius: 0,
    fontFamily: '"Lato", sans-serif',
    fontWeight: '700',
    fontSize: 14,
    paddingLeft: 30,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  buttonLeaf: {
    fontSize: 14,
    lineHeight: '17px',
    color: '#878AA0',
    padding: theme.spacing(2, 0),
    paddingLeft: 62,
    letterSpacing: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
    borderRadius: 0,
    fontFamily: '"Lato", sans-serif',
    borderRight: '5px solid transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.75,
      color: '#111026',
    },
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    background: '#f7f7f7',

    '&:before': {
      display: 'block',
      backgroundColor: theme.palette.primary.main,
      width: 4,
      content: '" "',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
    },

    '.menuIcon': {
      color: theme.palette.primary.main,
    },
  },
  activeSubmenu: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
     background: '#85883e1c !important',
    //background: '#f5484812 !important',
  },
  menuIcon: {
    fontSize: 16,
    marginRight: 12,
    textAlign: 'center',
  },
  menuIconFull: {
    textAlign: 'center',
  },
  menuTitle: {
    padding: 0,
    opacity: 1,
    flexGrow: 1,
    textAlign: 'left',

    transition: theme.transitions.create(['opacity', 'max-width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    overflow: 'hidden',
  },
  menuPopover: {
    borderRadius: 0, // theme.spacing(0, 0.5, 0.5, 0),
    boxShadow: '2px 2px 1px -1px rgba(0,0,0,0.1), 1px 1px 1px 0px rgba(0,0,0,0.1), 1px 1px 3px 0px rgba(0,0,0,0.1)',
    minWidth: 180,
  },
  menuPopper: {
    /* background: '#fff', */
    boxShadow: '2px 2px 1px -1px rgba(0,0,0,0.1), 1px 1px 1px 0px rgba(0,0,0,0.1), 1px 1px 3px 0px rgba(0,0,0,0.1)',
    minWidth: 180,
    zIndex: 140,
  },
  toggleIcon: {
    fontSize: 14,
    width: 40,
    textAlign: 'center',
    marginRight: 0,

    '& > .fal': {
      fontSize: 16,
    },
  },
}));

function AppDrawerNavItem(props) {
  const {
    active,
    children,
    depth,
    href,
    icon,
    isMobile,
    onClick,
    openImmediately = false,
    topLevel = false,
    title,
    linkProps,
    drawerOpen,
    showToggle,
    pageObject,
    ...other
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(openImmediately);

  const handleButtonClick = event => {
    if (props.onClick) props.onClick(event);
  };

  const style = {
    padding: '12px 0 12px 30px',
  };

  if (depth) {
    style.padding = '15px 0 15px 62px';
  }

  let menuIcon = icon && <span className={clsx(classes.menuIcon, !drawerOpen && classes.menuIconFull)}>{icon}</span>;
  if (menuIcon && !drawerOpen) {
    menuIcon = (
      <Tooltip title={title} placement="top">
        {menuIcon}
      </Tooltip>
    );
  }

  // Is divider instead?
  if (pageObject && pageObject.divider === true) {
    return (
      <ListItem className={classes.itemLeaf} disableGutters {...other}>
        <Box flexGrow={1}>
          <Divider />
        </Box>
      </ListItem>
    );
  }

  // Is divider instead?
  if (pageObject && pageObject.divider === true) {
    return (
      <ListItem className={classes.itemLeaf} disableGutters {...other}>
        <Box flexGrow={1}>
          <Divider />
        </Box>
      </ListItem>
    );
  }

  if (href) {
    return (
      <ListItem className={classes.itemLeaf} disableGutters {...other}>
        <Button
          size="large"
          component={Link}
          to={href}
          /* activeClassName={`drawer-active ${classes.active}`} */
          href={href}
          className={clsx(
            classes.buttonLeaf,
            `depth-${depth}`,
            active && 'drawer-active',
            active && depth === 0 && classes.active,
            active && depth >= 0 && classes.activeSubmenu,
          )}
          disableTouchRipple
          onClick={handleButtonClick}
          style={style}
          {...linkProps}>
          {menuIcon}
          <span className={[classes.menuTitle].join(' ')}>{`${title}`}</span>
        </Button>
      </ListItem>
    );
  }

  return (
    <ListItem className={classes.item} disableGutters {...other}>
      <Button
        disableTouchRipple
        size="large"
        classes={{
          root: classes.button,
          label: topLevel ? 'algolia-lvl0' : '',
          endIcon: classes.toggleIcon,
        }}
        style={style}>
        {menuIcon}
        <span className={[classes.menuTitle].join(' ')}>{`${title}`}</span>
      </Button>

      {children}
    </ListItem>
  );
}

AppDrawerNavItem.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  linkProps: PropTypes.object,
  onClick: PropTypes.func,
  openImmediately: PropTypes.bool,
  title: PropTypes.string.isRequired,
  topLevel: PropTypes.bool,
};

export default AppDrawerNavItem;
