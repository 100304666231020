export const styles = theme => ({
  input: {
    marginBottom: theme.spacing(3),
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(540 + theme.spacing(3) * 2)]: {
      width: 540,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4, 6, 7),
    minHeight: 220,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formLogin: {
    padding: theme.spacing(0, 3),
  },
  submit: {
    marginTop: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    padding: theme.spacing(1.5, 3),
    fontSize: 16,
  },
  error: {
    color: theme.palette.error.main,
    marginTop: 0,
    marginBottom: theme.spacing(2),
    visibility: 'hidden',
    padding: '0 24px',
    textAlign: 'center',
  },
  logo: {
    padding: '20px 30px 50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  logoImg: {
    maxHeight: 80,
    marginRight: 16,
  },
  logoText: {
    fontWeight: 300,
    fontSize: '30px',
    marginTop: '-2px',
    color: theme.palette.brand,
  },
  copyright: {
    marginTop: 30,
    textAlign: 'center',
    fontSize: 14,
    color: '#a2a2a2'
  }
});
