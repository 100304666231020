import React from 'react';
import { withStyles } from '@material-ui/styles';
import { InputAdornment, IconButton, InputLabel, Select, FormControl, TextField } from '@material-ui/core';
import clsx from 'clsx';
import Icon from '../icon';

const styles = theme => ({
  root: {},
  filters: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: 48,
  },
  hidden: {
    display: 'none',
  },
  search: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  searchOpen: {
    minWidth: 280,
  },
  searchButton: {
    padding: 12,
  },
  searchField: {
    flexGrow: 1,
  },
  select: {
    marginRight: theme.spacing(2),
  },
  selectInput: {
    width: 160,
  },
});

@withStyles(styles)
class TableFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearch: false,
    };
  }

  clearSearch = () => {
    this.setState({ showSearch: false });
  };

  showSearch = () => {
    this.setState({ showSearch: true });
  };

  render() {
    const { classes, filters } = this.props;
    const { showSearch } = this.state;

    const filtersContent = filters.map(filter => {
      const { label, onChange, type, options, value } = filter;
      // Search filter
      if (type === 'search') {
        return (
          <div key={`tf-${type}-${label}`} className={clsx(classes.search, showSearch && classes.searchOpen)}>
            <IconButton className={classes.searchButton} size="small" onClick={() => this.showSearch()}>
              <Icon name="search" />
            </IconButton>

            {showSearch && (
              <TextField
                autoFocus
                className={classes.searchField}
                size="small"
                value={value || ''}
                onChange={ev => onChange(ev.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          this.clearSearch();
                          onChange('');
                        }}>
                        <Icon name="times" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </div>
        );
      }

      // Select filters
      if (type === 'select') {
        return (
          <div key={`tf-${type}-${label}`} className={clsx(classes.select, showSearch && classes.hidden)}>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <InputLabel id={`filter-${type}-label-outlined`} shrink>{label}</InputLabel>
              <Select
                label={label}
                labelId={`filter-${type}-label-outlined`}
                displayEmpty
                inputProps={{
                  name: 'role',
                  id: `tf-${type}-placeholder`,
                }}
                className={classes.selectInput}
                native
                value={value || ''}
                onChange={ev => onChange(ev.target.value)}>
                {options.map(o => (
                  <option key={`tfs-${type}-${o.value}`} value={o.value}>
                    {o.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
        );
      }
    });

    return <div className={classes.filters}>{filtersContent}</div>;
  }
}

export default TableFilters;
