import React from 'react';
import { Box, Menu, MenuItem, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import DrawerToogle from '../containers/drawer-toggle';
import { styles } from '../styles/header-area.styles';
import AuthStore from '../../../lib/stores/auth-store';
import Icon from '../../../components/icon';
import { authApi } from '../../../api/authApi';

const HeaderArea = ({ classes, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await authApi.logout();
      AuthStore.logoutUser();
      window.location = '/login';
    } catch (err) {
      AuthStore.logoutUser();
      window.location = '/login';
    }

    setTimeout(() => (window.location = '/login'), 500);
  };

  const user = AuthStore.getUser();

  const handleMyAccount = () => {
    handleClose();
    window.location = `/user/edit/${user.id}`;
  };

  return (
    <Box component="header" className={clsx([classes.root, 'd-flex'])}>
      <DrawerToogle classes={classes} />
      <Box className="flex-grow-1 text-right">
        <Box>
          <Typography
            component="span"
            variant="body1"
            className={classes.userMenuToggle}
            aria-controls="user-header-menu"
            aria-haspopup="true"
            onClick={handleClick}>
            {`${user.first_name ? user.first_name : ''} ${user.last_name ? user.last_name : ''}`}
            <Icon name="chevron-down ml-1" />
          </Typography>
          <Menu
            id="user-header-menu"
            variant="menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '24ch',
              },
            }}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}>
            {user && user.id && <MenuItem onClick={handleMyAccount}>Contul meu</MenuItem>}
            <MenuItem onClick={handleLogout}>Iesire</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(HeaderArea);
