import { call, put } from 'redux-saga/effects';

import { fetchChannelsError, fetchChannelsSuccess } from '../actions';
import { parseApiError } from '../../../api/helpers';
import { channelApi } from '../../../api/channel';

export function* fetchChannelsSaga() {
  try {
    const response = yield call(channelApi.fetch);

    if (!response.ok) {
      yield put(fetchChannelsError(parseApiError(response).message));
      return;
    }

    yield put(fetchChannelsSuccess(response.data.data.results || []));
  } catch (err) {
    yield put(fetchChannelsError(parseApiError(err).message));
  }
}
