import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Prompt, withRouter } from 'react-router';
import _ from 'lodash';
import { offerApi } from '../../../api/offer';
import ContentPaper from '../../../components/content-paper';
import { translate } from '../../../i18n';
import LoadingText from '../../../components/loading-text';
import FormActions from '../../../components/form-actions';
import { parseApiError } from '../../../api/helpers';
import { FormContainer } from '../../../components/forms/container';
import { FormRow } from '../../../components/forms/row';
import OfferItems from './offer-items';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  buttonBlock: {
    display: 'block',
    width: '140px',
    margin: '0 auto',
    marginBottom: theme.spacing(1),
  },
  buttonDelete: {
    margin: theme.spacing(1),
    color: theme.palette.error.dark,
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },

  helperText: {
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  imagePreview: {
    width: '100px',
    height: '100px',
    backgroundColor: '#fafafa',
    margin: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  img: {
    width: 'auto',
    maxHeight: '90%',
    maxWidth: '90%',
    marginTop: '50%',
    transform: 'translateY(-50%)',
  },
});

@withStyles(styles)
@withRouter
class OfferForm extends Component {
  constructor(props) {
    super(props);

    // Set page title
    const { id, setPageTitle } = this.props;
    let pageTitle = translate('Adauga lucrare');
    if (id) {
      pageTitle = translate('Modifica lucrare');
    }
    setPageTitle(pageTitle);

    this.state = {
      pageTitle,
      id: props.id ? props.id : null,
      data: !_.isEmpty(props.data)
        ? props.data
        : {
            suspended: true,
          },
      newData: {},
    };
  }

  componentDidMount() {
    const { fetchChannels, fetchUsers, fetchOffers } = this.props;

    fetchOffers();
    fetchChannels();
    fetchUsers();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.id) {
      this.setState({
        id: nextProps.id,
        data: nextProps.data,
      });
    }
  }

  handleSubmit = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    const { newData, id } = this.state;
    const { enqueueSnackbar, onSave } = this.props;

    try {
      const response = !id ? await offerApi.add(newData) : await offerApi.update(id, newData);

      if (!response.ok) {
        enqueueSnackbar(parseApiError(response).message, { options: { variant: 'error' } });
        return;
      }

      this.setState({ hasUnsavedChanges: false });
      enqueueSnackbar('Lucrare salvata');

      if (onSave) {
        onSave(response);
      }

      this.handleAfterSave();
    } catch (e) {
      enqueueSnackbar('A aparut o eroare la salvarea lucrarii', { options: { variant: 'error' } });
    }
  };

  handleAfterSave = () => {
    const { history, onAfterSave } = this.props;
    if (onAfterSave) {
      onAfterSave();
      return;
    }

    history.push('/offer');
  };

  handleDelete = async () => {
    const { id } = this.state;
    const { enqueueSnackbar, history } = this.props;

    // Reset state
    this.setState({ hasUnsavedChanges: false });

    // Delete AJAX
    try {
      await offerApi.deleteById(id);

      enqueueSnackbar('Lucrare stearsa');
      history.push('/offer');
    } catch (e) {
      enqueueSnackbar('Lucrarea nu a putut fi stearsa', { options: { variant: 'error' } });
    }
  };

  handleChange = (name, value) => event => {
    const { data, newData } = this.state;
    value = typeof value !== 'undefined' ? value : event.target.value;

    this.setState({
      hasUnsavedChanges: true,
      data: { ...data, [name]: value },
      newData: { ...newData, [name]: value },
    });
  };

  handleItemsChange = nextItems => {
    const { data, newData } = this.state;

    this.setState({
      data: { ...data, items: nextItems },
      newData: { ...newData, items: nextItems },
    });
  };

  load = () => {
    const { id, fetchOfferById } = this.props;
    fetchOfferById(id);
  };

  shouldComponentRender = () => {
    const { id, data } = this.state;

    if (id && !data) {
      return false;
    }

    return true;
  };

  canSubmitForm = () => {
    const { data } = this.state;
    // eslint-disable-next-line camelcase
    const { client_name, channel_id } = data;

    // eslint-disable-next-line camelcase
    return client_name && channel_id;
  };

  render() {
    const { classes, channels, users, usersById } = this.props;
    const { data, hasUnsavedChanges, pageTitle } = this.state;

    if (!this.shouldComponentRender()) {
      return <LoadingText />;
    }

    return (
      <>
        <Prompt when={!!hasUnsavedChanges} message="Modificarile nu sunt salvate. Esti sigur ca vrei sa continui?" />
        <Typography variant="h5" component="h2" className="mb-5">
          {pageTitle || ''}
        </Typography>

        <Typography variant="h6" className="mb-2">
          Informatii client
        </Typography>
        <ContentPaper className="FormBoxPaper mb-3">
          <FormContainer>
            <FormRow>
              <TextField
                required
                name="client_name"
                label="Nume client"
                className={classes.textField}
                value={data.client_name || ''}
                onChange={this.handleChange('client_name')}
              />
              <TextField
                name="company_name"
                label="Firma client"
                className={classes.textField}
                value={data.company_name || ''}
                onChange={this.handleChange('company_name')}
              />
            </FormRow>
          </FormContainer>

          <FormContainer>
            <FormRow>
              <TextField
                name="client_phone"
                label="Telefon"
                className={classes.textField}
                value={data.client_phone || ''}
                onChange={this.handleChange('client_phone')}
              />
              <TextField
                name="client_email"
                label="Adresa email"
                className={classes.textField}
                value={data.client_email || ''}
                onChange={this.handleChange('client_email')}
              />
            </FormRow>
          </FormContainer>

          <FormContainer>
            <FormRow>
              <TextField
                name="website_order_number"
                label="Numar comanda site"
                className={classes.textField}
                value={data.website_order_number || ''}
                onChange={this.handleChange('website_order_number')}
                helperText="Numarul comenzii din site"
              />
              <TextField
                name="website_order_id"
                label="ID comanda site"
                className={classes.textField}
                value={data.website_order_id || ''}
                onChange={this.handleChange('website_order_id')}
                helperText="ID-ul comenzii din site"
              />
            </FormRow>
          </FormContainer>

          <FormContainer>
            <FormRow>
              <TextField
                name="client_notes"
                label="Mentiuni suplimentare"
                className={classes.textField}
                value={data.client_notes || ''}
                onChange={this.handleChange('client_notes')}
                helperText="Mentiuni adaugate de client in momentul crearii cererii de oferta"
                multiline
              />
            </FormRow>
          </FormContainer>
        </ContentPaper>

        <Typography variant="h6" className="mb-2">
          Detalii lucrare
        </Typography>
        <ContentPaper className="FormBoxPaper mb-3">
          <FormContainer>
            <FormRow>
              <TextField
                name="client_city"
                label="Oras"
                className={classes.textField}
                value={data.client_city || ''}
                onChange={this.handleChange('client_city')}
              />
              <Autocomplete
                options={Object.values(channels || []).map(c => ({ id: c.id, name: c.name }))}
                getOptionLabel={channel => channel.name}
                disableClearable
                openOnFocus
                fullWidth
                value={{
                  id: data.channel_id,
                  name: channels[parseInt(data.channel_id, 10)] ? channels[parseInt(data.channel_id, 10)].name : '',
                }}
                onChange={(event, value) => this.handleChange('channel_id', value.id.toString())()}
                renderInput={params => (
                  <TextField
                    required
                    {...params}
                    label="Regiune"
                    helperText="Alege judetul in care este listata lucrarea"
                  />
                )}
              />
            </FormRow>
            <FormRow>
              <Autocomplete
                disabled={data.status !== 'started'}
                options={Object.values(users || []).map(user => ({
                  id: user.id,
                  name: `${user.first_name} ${user.last_name}`,
                }))}
                getOptionLabel={user => user.name}
                disableClearable
                openOnFocus
                fullWidth
                value={{
                  id: data.assigned_user,
                  name: usersById[parseInt(data.assigned_user, 10)]
                    ? `${usersById[parseInt(data.assigned_user, 10)].first_name} ${
                        usersById[parseInt(data.assigned_user, 10)].last_name
                      }`
                    : '',
                }}
                onChange={(event, value) => this.handleChange('assigned_user', value.id.toString())()}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Instalator"
                    helperText="Se poate schimba doar pentru comenzile in desfasurare"
                  />
                )}
              />

              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="role-label-outlined">Rating instalator</InputLabel>
                <Select
                  label="Rating instalator"
                  value={data.rating_score || ''}
                  onChange={this.handleChange('rating_score')}
                  inputProps={{
                    name: 'rating_score',
                    id: 'rating_score-label-placeholder',
                  }}
                  labelId="rating_score-label-outlined"
                  id="rating_score"
                  name="rating_score">
                  {[1, 2, 3, 4, 5].map(v => (
                    <MenuItem value={`${v}`}>{v}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormRow>
          </FormContainer>

          <FormContainer>
            <FormRow>
              <TextField
                name="cancel_note"
                label="Observatii instalator"
                className={classes.textField}
                value={data.cancel_note || ''}
                onChange={this.handleChange('cancel_note')}
                helperText="Mesajul adaugat de instalator la anularea comenzii"
                multiline
              />
            </FormRow>
          </FormContainer>

          <FormContainer>
            <FormRow cols={2}>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    checked={!data.suspended}
                    onChange={(event, value) => {
                      this.handleChange('suspended', value ? 0 : 1)();
                    }}
                    color="primary"
                  />
                }
                label="Lucrare publicata"
              />
            </FormRow>
          </FormContainer>
        </ContentPaper>

        <Typography variant="h6" className="mb-1">
          Produse
        </Typography>
        <ContentPaper className="FormBoxPaper mb-3">
          <OfferItems items={data.items || [{ id: null, name: '', quantity: '' }]} onChange={this.handleItemsChange} />
        </ContentPaper>

        <FormActions
          onDelete={this.handleDelete}
          onCancel={this.handleAfterSave}
          onSubmit={this.handleSubmit}
          disableSubmit={!this.canSubmitForm()}
        />
      </>
    );
  }
}

export default OfferForm;
