import produce from 'immer';

import {
  SYSTEM_SETTINGS_FETCH_REQUEST,
  SYSTEM_SETTINGS_FETCH_SUCCESS,
  SYSTEM_SETTINGS_FETCH_ERROR,
  SYSTEM_SETTINGS_UPDATE_SETTING,
} from '../actions';

const INITIAL_STATE = {
  settings: [],
  selected: null,

  // Other state
  loading: false,
  initialLoaded: false,
  loadingId: null,
  loadingIds: [],

  fetchError: null,
  saveError: null,
};

// Define the order schema so we can normalize the data and index it properly

export default function system(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SYSTEM_SETTINGS_FETCH_SUCCESS:
        // Normalize the data for better access
        const { data } = action.payload;
        draft.settings = data;
        draft.loading = false;
        draft.initialLoaded = true;
        draft.fetchError = null;

        break;

      case SYSTEM_SETTINGS_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case SYSTEM_SETTINGS_FETCH_ERROR:
        draft.loading = false;
        draft.fetchError = action.payload.error;
        break;

      case SYSTEM_SETTINGS_UPDATE_SETTING:
        if (draft.settings && draft.settings.length) {
          const eIndex = draft.settings.findIndex(s => s.name === action.payload.name);
          if (eIndex !== -1) {
            draft.settings[eIndex].value = action.payload.value;
          }
        }
        break;

      default:
        break;
    }
  });
}
