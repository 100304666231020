import React from 'react';
import { connect } from 'react-redux';
import Users from '../components/users';
import { fetchUsers, hideUserAddDialog, setTableFilter, showUserAddDialog } from '../actions';
import { setPageTitle } from '../../ui/actions';
import { getFilteredUsers } from '../reducers';
import { enqueueSnackbar } from '../../snackbar/actions';
import { fetchChannels } from '../../channel/actions';

function mapStateToProps(state, ownProps) {
  let status = null;
  if (ownProps.status && typeof ownProps.status === 'string') {
    status = ownProps.status;
  }

  const users = getFilteredUsers(state, status);

  return {
    dataById: users || {},
    data: state.users.entitiesIds || [],
    filters: state.users.filters,
    selected: state.users.selected,
    loading: state.users.loading,
    initialLoaded: state.users.initialLoaded,
    fetchError: state.users.fetchError,
    loadingIds: state.users.loadingIds,
    channels: Object.values(state.channels.entities.channels),
  };
}

const mapDispatchToProps = {
  fetchData: fetchUsers,
  fetchChannels: fetchChannels,
  setPageTitle,
  setTableFilter,
  showAddDialog: showUserAddDialog,
  hideAddDialog: hideUserAddDialog,
  enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
