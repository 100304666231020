import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import { getOfferByIdSelector } from '../reducers';
import { setPageTitle as setPageTitleAction } from '../../ui/actions';
import { fetchOfferById, fetchOffers } from '../actions';
import { enqueueSnackbar as enqueueSnackbarAction } from '../../snackbar/actions';
import OfferForm from '../components/offer-form';
import { fetchChannels } from '../../channel/actions';
import { fetchUsers } from '../../user/actions';

const mapStateToProps = (state, ownProps) => {
  const sortedUsers = sortBy(Object.values(state.users.entities.users), ['first_name', 'last_name']);

  return {
    data: getOfferByIdSelector(state, parseInt(ownProps.id, 10) || null),
    channels: state.channels.entities.channels || {},
    users: sortedUsers,
    usersById: state.users.entities.users || {},
  };
};

const mapDispatchToProps = {
  setPageTitle: setPageTitleAction,
  fetchOfferById,
  enqueueSnackbar: enqueueSnackbarAction,
  fetchChannels,
  fetchUsers,
  fetchOffers,
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferForm);
