export const styles = theme => ({
  root: {
    padding: theme.spacing(0, 3, 1, 0),
    marginBottom: theme.spacing(4),
    borderBottom: '1px solid #E5E5E7',
    alignItems: 'center',
    minHeight: '70px',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  userMenuToggle: {
    cursor: 'pointer',
    padding: theme.spacing(1, 2),
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '22px',
  },
});
