import React, { Component } from 'react';
import { Redirect, Route, Router } from 'react-router-dom';
import { Provider as ReduxProvider, ReactReduxContext } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import MuiThemeProvider from '@material-ui/styles/ThemeProvider';
import { SnackbarProvider } from 'notistack';
import { Box, CssBaseline } from '@material-ui/core';
import { ConnectedRouter } from 'connected-react-router';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import { store, history } from './store';
import SnackbarNotifier from './features/snackbar';
import PrivateComponent from './components/private-component';
import theme from './theme/theme';
import { styles } from './features/ui/styles/global';
import HandleProgressBar from './features/ui/containers/progress-bar';
import RouteSwitcher from './navigation/route-switcher';
import AppDrawer from './features/ui/containers/drawer';
import ScrollToTop from './navigation/scroll-to-top';

import './theme/scss/base.scss';
import HeaderArea from './features/ui/components/header-area';

@withStyles(styles)
class App extends Component {
  constructor(props) {
    super(props);
    this.appRef = React.createRef();
  }

  render() {
    const { classes } = this.props;

    const isTouchCapable =
      'ontouchstart' in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch) ||
      navigator.maxTouchPoints > 0 ||
      window.navigator.msMaxTouchPoints > 0;

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ReduxProvider store={store} context={ReactReduxContext}>
          <ConnectedRouter history={history} context={ReactReduxContext}>
            <Router history={history}>
              <div className={classes.root}>
                {/* Scroll to top upon navigation */}
                <ScrollToTop />

                {/* Takes care of displaying a top loading progress bar */}
                <HandleProgressBar />

                <Route exact path="/" render={() => <Redirect to="/dashboard" />} />

                <CssBaseline />

                <PrivateComponent>
                  <AppDrawer />
                </PrivateComponent>

                <SnackbarProvider
                  maxSnack={5}
                  dense
                  disableWindowBlurListener
                  autoHideDuration={3000}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}>
                  <SnackbarNotifier />

                  <Box component="main" id="App-content" className={classes.content} ref={this.appRef}>
                    <PrivateComponent>
                      <HeaderArea />
                    </PrivateComponent>

                    <DragDropContextProvider backend={isTouchCapable ? TouchBackend : HTML5Backend}>
                      <RouteSwitcher appRef={this.appRef} />
                    </DragDropContextProvider>
                  </Box>
                </SnackbarProvider>
              </div>
            </Router>
          </ConnectedRouter>
        </ReduxProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
