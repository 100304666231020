import React from 'react';
import { Button, Box, CssBaseline, Typography, Paper, TextField, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { styles } from '../styles/ofer-view-form';
import Icon from '../../../components/icon';
import { offerApi } from '../../../api/offer';

const FORM_QUESTIONS = [
  {
    question: 'S-a finalizat montajul sistemului de securitate?',
    answer: '',
  },
  {
    question: 'Cat de multumit ai fost de serviciile instalatorului?',
    answer: '',
  },
  {
    question: 'Cat de multumit ai fost de pretul manoperei?',
    answer: '',
  }
];

@withStyles(styles)
class OfferReviewAddPublic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      error: false,
      errorMsg: false,
      loading: false,
      comment: '',
      answers: [...FORM_QUESTIONS],
      rating_score: 0,
    };
  }

  setAnswer = (id, value) => {
    let { answers } = this.state;

    answers[id].answer = value;
    this.setState({ answers})
  };

  submitForm = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    this.setState({
      loading: true,
      error: false,
      errorMsg: false,
      success: false,
    });

    const { guid, location } = this.props;
    const { answers, comment, rating_score } = this.state;
    try {
      const response = await offerApi.addOfferPublicReview(guid, {
        answers,
        comment,
        rating_score,
      });

      if (!response.ok) {
        window.scrollTo(0, 0);

        this.setState({
          error: true,
          errorMsg: 'A aparut o eroare la salvarea opiniei tale. Te rugam incearca din nou.',
          loading: false,
        });

        return;
      }

      setTimeout(() => {
        this.setState({
          success: true,
          loading: false,
        });
        window.scrollTo(0, 0);
      }, 1000)

    } catch (err) {
      this.setState({
        loading: false,
        error: true,
        errorMsg: 'A aparut o eroare la salvarea opiniei tale. Te rugam incearca din nou.',
      });

      window.scrollTo(0, 0);
    }
  };

  render() {
    const { classes, guid, match } = this.props;
    const { answers, loading, success, errorMsg } = this.state;
console.log('errorMsg', errorMsg)
    return (
      <main className={classes.main}>
        <CssBaseline />

        <Box className={classes.logo}>
          <img className={classes.logoImg} src="/spy-shop-logo.svg" alt="Spy Shop" />
        </Box>

        <Paper className={classes.paper} elevation={0}>
          <Typography component="h1" variant="h5" className="mb-2">
            Chestionar lucrare
          </Typography>

          <Typography
            component="p"
            variant="body1"
            style={{ color: 'green', textAlign: 'center', margin: 16, display: success ? '' : 'none' }}>
            Iti multumim pentru completarea formularului. Poti reveni oricand pe aceasta
            pagina pentru a schimba informatiile.
          </Typography>

          <Typography
            component="p"
            variant="body1"
            className={classes.error}
            style={{ display: errorMsg ? 'block' : 'none' }}>
            {errorMsg || '&nbsp;'}
          </Typography>

          <div className={clsx(classes.form, classes.formLogin)}>
            <Box className={classes.questionRow}>
              <Box className={classes.questionName}>S-a finalizat montajul sistemului de securitate?</Box>
              <Box className={classes.questionAnswer}>
                <RadioGroup row name="question1" /*defaultValue={answers[0] ? answers[0].answer : ''}*/>
                  <FormControlLabel value="Da" onChange={(ev) => this.setAnswer(0, 'Da')} control={<Radio color="primary" />} label="Da" />
                  <FormControlLabel value="Nu" onChange={(ev) => this.setAnswer(0, 'Nu')} control={<Radio color="primary" />} label="Nu" />
                </RadioGroup>
              </Box>
            </Box>


            <Box className={classes.questionRow}>
              <Box className={classes.questionName}>Cat de multumit ai fost de serviciile instalatorului?</Box>
              <Box className={classes.questionAnswer}>
                {[1, 2, 3, 4, 5].map(rating => {
                  const checked = rating <= parseInt(answers[1].answer);

                  return (
                    <Box style={{ display: 'inline-block' }}
                         key={`q-1-${rating}`}
                      onClick={() => this.setAnswer(1, rating.toString(10))}>
                      <Icon name="star" solid={checked} light={!checked}
                            className={clsx(classes.questionStar, checked && classes.fullStar )} />
                    </Box>
                  )
                })}
              </Box>
            </Box>


            <Box className={classes.questionRow}>
              <Box className={classes.questionName}>Cat de multumit ai fost de pretul manoperei?</Box>
              <Box className={classes.questionAnswer}>
                {[1, 2, 3, 4, 5].map(rating => {
                  const checked = rating <= parseInt(answers[2].answer);

                  return (
                    <Box style={{ display: 'inline-block' }}
                         key={`q-2-${rating}`}
                         onClick={() => {
                           this.setAnswer(2, rating.toString(10));
                           this.setState({
                             rating_score: ((parseInt(answers[1].answer, 10) || 0)
                              + (parseInt(answers[2].answer, 10) || 0)) / 2
                           })
                         }}>
                      <Icon name="star" solid={checked} light={!checked}
                            className={clsx(classes.questionStar, checked && classes.fullStar )} />
                    </Box>
                  )
                })}
              </Box>
            </Box>


            <Box>
              <Box className={classes.questionName}>Observatii</Box>
              <Box className={classes.questionAnswer}>
                  <TextField
                  multiline
                  rows={3}
                  className={classes.input}
                  fullWidth
                  label=""
                  placeholder="Lasa un mesaj mai detaliat despre experienta ta cu instalatorul"
                  id="comment"
                  name="comment"
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={ev => this.setState({ comment: ev.target.value })}
                />
              </Box>
            </Box>

            <Button
              onClick={this.submitForm}
              variant="contained"
              color="primary"
              disabled={loading}
              className={classes.submit}>
              Trimite opinia
            </Button>

            <Typography className={classes.copyright}>Spy Shop SRL &copy; Toate drepturile rezervate</Typography>
          </div>
        </Paper>
      </main>
    );
  }
}

export default OfferReviewAddPublic;
