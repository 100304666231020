import React from 'react';
import { Box, Link, Typography, withStyles } from '@material-ui/core';

import { withSnackbar } from 'notistack';

import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import ContentPaper from '../../../components/content-paper';
import LoadingText from '../../../components/loading-text';
import Icon from '../../../components/icon';
import styles from '../styles';

@withSnackbar
@withStyles(styles)
class Ratings extends React.Component {
  refreshInterval = null;

  constructor(props) {
    super(props);
    const { fetchData, setPageTitle, type } = this.props;

    this.typeName = 'rating';

    const pageConfig = {
      title: 'Evaluari',
      showAddButton: false,
    };

    setPageTitle(pageConfig.title);
    fetchData();

    this.state = {
      pageConfig,
    };
  }

  componentDidMount() {
    const { fetchData } = this.props;

    // Refresh every 10 seconds
    this.refreshInterval = setInterval(() => fetchData(), 60000);
  }

  componentWillUnmount() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  getTableColumns = () => {
    const { classes, theme, type } = this.props;

    let columns = [
      /*{
        id: 'id2',
        accessor: 'id',
        sortable: true,
        label: 'ID',
        style: {
          width: 70,
        },
      },*/
      {
        id: 'assigned_user',
        accessor: 'assigned_user',
        label: 'Instalator',
        sortable: true,
        renderValue: row => {
          if (row.assigned_user && row.user && row.user.id) {
            return (
              <Link onClick={event => event.stopPropagation()} href={`/user/edit/${row.user.id}`}>
                {`${row.user.first_name} ${row.user.last_name}`}
              </Link>
            );
          }

          return null;
        },
      },

      {
        id: 'rating_score',
        accessor: 'rating_score',
        sortable: true,
        label: 'Rating',
        style: {
          minWidth: 150,
        },
        renderValue: row => {
          if (!row.rating_score) {
            return null;
          }

          return ([...Array(parseInt(row.rating_score, 10)).keys()].map( (i) =>
            <Icon key={`rw-${row.id}-${i}`} light={false} solid={true} name="star" style={{color: '#f0d355'}} />
          ))
        },
      },

      {
        id: 'rating_score_details',
        accessor: 'rating_score_details',
        label: 'Raspunsuri',
        sortable: true,
        renderValue: row => {
          const details = row.rating_score_details ? JSON.parse(row.rating_score_details) : null;

          if (!details || !details.answers || !details.answers.length) {
            return null;
          }

          return details.answers.map(question => (
            <Box key={`row-${row.id}-answer-${question.question}`}>
              <p style={{ fontSize: 12, margin: 0, marginBottom: 5 }}><span style={{fontWeight: '600'}}>{question.question}</span>
                <br/>{question.answer}</p>
            </Box>
          ))
        },
      },

      {
        id: 'rating_comment',
        accessor: 'rating_comment',
        sortable: true,
        label: 'Observatii',
        renderValue: (row) => (<p style={{ fontSize: 12 }}>{row.rating_comment}</p>),
      },

      {
        id: 'client_name',
        accessor: 'client_name',
        sortable: true,
        label: 'Nume client',
      },

      {
        id: 'created_at',
        accessor: 'created_at',
        label: 'Adaugat',
        sortable: true,
        renderValue: row => {
          const details = row.rating_score_details ? JSON.parse(row.rating_score_details) : null;

          if (!details || !details.rating_date) {
            return null;
          }
          const date = new Date(details.rating_date);
          return (
            <>
              <Box>{date.toLocaleTimeString()}</Box>
              <Box>{date.toLocaleDateString()}</Box>
            </>
          )
        },
      },
    ];

    // Now let's alter a bit the columns based on our current view type
    if (type !== undefined && type) {

      if (type === 'completed') {
        columns = [
          ...columns.slice(0, columns.length - 2),
          {
            id: 'status_updated_at',
            accessor: 'status_updated_at',
            label: 'Finalizata',
            sortable: true,
            renderValue: row =>
              !row.status_updated_at ? null : (
                <>
                  <Box>{new Date(row.status_updated_at).toLocaleTimeString()}</Box>
                  <Box>{new Date(row.status_updated_at).toLocaleDateString()}</Box>
                </>
              ),
          },
          columns[columns.length - 2],
        ];
      }
    }

    return columns;
  };

  getTableFilters = () => {
    const { setTableFilter, filters } = this.props;

    return [
      {
        label: 'Cauta',
        type: 'search',
        value: filters.search,
        onChange: value => setTableFilter('search', value),
      },
    ];
  };

  shouldComponentRender = () => {
    const { initialLoaded } = this.props;

    return initialLoaded;
  };

  getLoadingSkeleton = () => {
    return <LoadingText />;
  };

  render() {
    const { classes, dataById, pageTitle, type } = this.props;

    const data = Object.values(dataById);

    if (!this.shouldComponentRender()) {
      return <div className={classes.root}>{this.getLoadingSkeleton()}</div>;
    }

    return (
      <div className={classes.root}>
        <Typography variant="h5" component="h2" className="mb-3">
          {pageTitle || 'Evaluari'}
        </Typography>
        <ContentPaper fullWidth className={classes.paper}>
          <EnhancedTable
            noCheckbox
            filters={this.getTableFilters()}
            columns={this.getTableColumns()}
            data={data}
            rowCount={data.length}
            order="desc"
            orderBy="created_at"
            size="small"
          />
        </ContentPaper>
      </div>
    );
  }
}

export default Ratings;
