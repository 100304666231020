import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import React from 'react';
import { Box, Button, ButtonGroup, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { notificationApi } from '../../../api/notification';
import { translate } from '../../../i18n';
import ContentPaper from '../../../components/content-paper';
import { FormContainer } from '../../../components/forms/container';
import { FormRow } from '../../../components/forms/row';
import FormActions from '../../../components/form-actions';
import ConfirmationDialog from '../../../components/confirmation-dialog';
import { parseApiError } from '../../../api/helpers';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  buttonDelete: {
    margin: theme.spacing(1),
    color: theme.palette.error.dark,
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  helperText: {
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

@withStyles(styles)
@withRouter
class SendNotification extends React.Component {
  constructor(props) {
    super(props);

    // Set page title
    const { setPageTitle } = this.props;
    setPageTitle(translate('Trimite notificare PUSH'));

    this.state = {
      loading: false,
      showSubmitConfirmation: false,
      data: {
        title: '',
        message: '',
        recipientsType: 'all', // Can be 'all', 'users', 'ios', 'android'
        recipientsList: [],
      },
    };
  }

  componentDidMount() {
    const { fetchUsers } = this.props;

    fetchUsers();
  }

  canSubmitForm = () => {
    const { data, loading } = this.state;
    const { message, recipientsList, recipientsType } = data;
    if (loading || !(message || '').trim()) {
      return false;
    }

    if (recipientsType !== 'users') {
      return true;
    }

    return (recipientsList || []).length > 0;
  };

  handleChange = (name, value) => (event) => {
    const { data } = this.state;
    value = typeof value !== 'undefined' ? value : event.target.value;

    this.setState({
      data: { ...data, [name]: value },
    });
  };

  cancelSubmit = () => {
    this.setState({ showSubmitConfirmation: false });
  };

  confirmSubmit = () => {
    this.setState({ showSubmitConfirmation: true });
  };

  handleSubmit = async (ev) => {
    if (ev) {
      ev.preventDefault();
    }

    const { enqueueSnackbar } = this.props;
    const { data } = this.state;
    this.setState({ loading: true });

    try {
      const response = await notificationApi.send(data);

      if (!response.ok) {
        enqueueSnackbar(parseApiError(response).message, { options: { variant: 'error' } });
        return;
      }

      enqueueSnackbar('Notificarea a fost trimisa cu succes', {
        options: { variant: 'success' },
      });

      this.handleAfterSave();
    } catch (e) {
      enqueueSnackbar('A aparut o eroare la trimiterea notificarii', { options: { variant: 'error' } });
    }
  };

  handleAfterSave = () => {
    const { history } = this.props;
    history.push('/notification');
  };

  render() {
    const { classes, users } = this.props;
    const { data, showSubmitConfirmation } = this.state;

    return (
      <>
        <Typography variant="h5" component="h2" className="mb-5">
          {translate('Trimite notificare noua')}
        </Typography>
        <ContentPaper className="FormBoxPaper mb-3">
          <Typography variant="h6" className="mb-2">
            {translate('Informatii mesaj')}
          </Typography>
          <FormContainer>
            <FormRow>
              <TextField
                name="title"
                label="Titlu"
                className={classes.textField}
                value={data.title || ''}
                onChange={this.handleChange('title')}
                helperText="Nu este obligatoriu"
              />
            </FormRow>
            <FormRow>
              <TextField
                required
                name="message"
                label="Mesaj notificare"
                className={classes.textField}
                value={data.message || ''}
                onChange={(ev) => {
                  const message = (ev.target.value || '').substring(0, 1000);
                  this.handleChange('message', message)(ev);
                }}
                helperText="Mesajul notificarii, in format text simplu. Maxim 1000 caractere"
                multiline
              />
            </FormRow>
          </FormContainer>

          <Typography variant="h6" className="mb-2">
            {translate('Destinatari')}
          </Typography>
          <Box mb={3}>
            <ButtonGroup variant="contained" color="primary" disableElevation>
              <Button
                style={data.recipientsType === 'all' ? { backgroundColor: '#686b2d', borderColor: '#686b2d' } : {}}
                onClick={this.handleChange('recipientsType', 'all')}>
                Toti instalatorii
              </Button>
              <Button
                style={data.recipientsType === 'users' ? { backgroundColor: '#686b2d', borderColor: '#686b2d' } : {}}
                onClick={this.handleChange('recipientsType', 'users')}>
                Unii instalatori
              </Button>
              <Button
                style={data.recipientsType === 'ios' ? { backgroundColor: '#686b2d', borderColor: '#686b2d' } : {}}
                onClick={this.handleChange('recipientsType', 'ios')}>
                Doar iOS
              </Button>
              <Button
                style={data.recipientsType === 'android' ? { backgroundColor: '#686b2d', borderColor: '#686b2d' } : {}}
                onClick={this.handleChange('recipientsType', 'android')}>
                Doar Android
              </Button>
            </ButtonGroup>
          </Box>

          {data.recipientsType === 'users' ? (
            <FormRow>
              <Autocomplete
                options={Object.values(users || []).map((user) => ({
                  id: user.id,
                  name: `${user.first_name} ${user.last_name}`,
                }))}
                getOptionLabel={(user) => user.name}
                getOptionSelected={(option, value) => option.id === value.id}
                disableClearable
                openOnFocus
                fullWidth
                multiple
                value={data.recipientsList}
                onChange={(event, value) => {
                  this.handleChange('recipientsList', value)();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cauta&hellip;"
                    helperText={translate('Alege instalatorii carora doresti sa le trimiti notificarea')}
                  />
                )}
              />
            </FormRow>
          ) : null}
        </ContentPaper>

        <FormActions
          saveLabel={translate('Trimite')}
          onCancel={this.handleAfterSave}
          onSubmit={this.confirmSubmit}
          disableSubmit={!this.canSubmitForm()}
        />

        {!showSubmitConfirmation ? null : (
          <ConfirmationDialog
            title={translate('Confirmare notificare')}
            message={translate(
              'Esti sigur ca vrei sa trimiti notificarea? Odata trimisa, aceasta nu poate fi anulata.',
            )}
            confirm={this.handleSubmit}
            cancel={this.cancelSubmit}
            show
          />
        )}
      </>
    );
  }
}

export default SendNotification;
