import { makeActionCreator } from '../../../store/actions';

export const NOTIFICATIONS_FETCH_REQUEST = '@notifications / FETCH REQUEST';
export const NOTIFICATIONS_FETCH_SUCCESS = '@notifications / FETCH SUCCESS';
export const NOTIFICATIONS_FETCH_ERROR = '@notifications / FETCH ERROR';
export const NOTIFICATIONS_SET_TABLE_FILTER = '@notifications / SET TABLE FILTER';

export const fetchNotifications = makeActionCreator(NOTIFICATIONS_FETCH_REQUEST);
export const fetchNotificationsError = makeActionCreator(NOTIFICATIONS_FETCH_ERROR, 'error');
export const fetchNotificationsSuccess = makeActionCreator(NOTIFICATIONS_FETCH_SUCCESS, 'data');

// Table filters
export const setTableFilter = makeActionCreator(NOTIFICATIONS_SET_TABLE_FILTER, 'name', 'value');
