import React from 'react';
import { withRouter } from 'react-router-dom';

import { List } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';

import _ from 'lodash';
import Box from '@material-ui/core/Box';
import AppDrawerNavItem from './AppDrawerNavItem';

import { pageToTitleI18n } from '../util/helpers';
import AuthStore from '../lib/stores/auth-store';
import pages, { findActivePage } from '../pages';
import { translate } from '../i18n';

function renderNavItems(options) {
  const { pages: navItems, ...params } = options;

  return (
    <List className="py-0">
      {navItems.reduce(
        // eslint-disable-next-line no-use-before-define
        (items, page) => reduceChildRoutes({ items, page, ...params }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({ props, activePage, items, page, depth, t, drawerOpen, isMobile }) {
  if (page.displayNav === false) {
    return items;
  }

  const userData = AuthStore.getUser();

  // Check role
  if (page.requireRole && (!userData || !userData.role)) {
    return items;
  }

  if (page.requireRole) {
    const role = page.requireRole;

    if (typeof role === 'string' && role !== userData.role) {
      return items;
    }

    if (role.indexOf(userData.role) === -1) {
      return items;
    }
  }

  let currentPage = activePage.pathname === page.pathname;

  if (page.children && page.children.length > 1) {
    const title = pageToTitleI18n(page, t);
    const topLevel = activePage.pathname.indexOf(`${page.pathname}/`) === 0;

    // Check if any of the children is active; if yes, we make this active too
    if (!currentPage) {
      currentPage =
        _.findIndex(page.children, c => {
          return c.pathname === activePage.pathname;
        }) !== -1;
    }

    items.push(
      <AppDrawerNavItem
        drawerOpen={drawerOpen}
        linkProps={page.linkProps}
        pageObject={page}
        depth={depth}
        key={title}
        topLevel={topLevel && !page.subheader}
        openImmediately={topLevel || Boolean(page.subheader) || page.startOpen || (drawerOpen && currentPage)}
        title={title}
        icon={page.icon ? page.icon : null}
        showToggle={drawerOpen}
        isMobile={isMobile}
        active={currentPage}>
        {renderNavItems({ props, pages: page.children, activePage, depth: depth + 1, t, drawerOpen })}
      </AppDrawerNavItem>,
    );
  } else {
    const title = pageToTitleI18n(page, t);
    page = page.children && page.children.length === 1 ? page.children[0] : page;

    items.push(
      <AppDrawerNavItem
        drawerOpen={drawerOpen}
        linkProps={page.linkProps}
        pageObject={page}
        depth={depth}
        key={title}
        title={title}
        icon={page.icon ? page.icon : null}
        isMobile={isMobile}
        active={currentPage}
        href={page.pathname}
        onClick={props.onClose}
      />,
    );
  }

  return items;
}

const SidebarDrawer = props => {
  const { classes, drawerOpen, isMobile } = props;
  const t = translate;

  // Determine active page
  let { pathname } = window.location;

  // Add support for leading / in development mode.
  if (pathname !== '/') {
    pathname = pathname.replace(/\/$/, '');
  }

  let activePage = findActivePage(pages, pathname);
  if (!activePage) {
    activePage = {
      pathname: '',
    };
  }

  return (
    <div id="sidebar-wrapper" className={classes.sidebar}>
      <Box className={classes.logo}>
        <img className={classes.logoImg} src="/spy-shop-logo.svg" alt="Spy Shop B2B" />
        <span className={classes.logoText}>B2B</span>
      </Box>

      <Box style={{ height: 'calc(100vh - 112px)' }}>
        <Scrollbars>
          <Box className="pr-2">{renderNavItems({ props, pages, activePage, depth: 0, t, drawerOpen, isMobile })}</Box>
        </Scrollbars>
      </Box>
    </div>
  );
};

export default withRouter(SidebarDrawer);
