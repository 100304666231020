import { connect } from 'react-redux';
import DrawerToogleComponent from '../components/drawer-toggle';
import { closeDrawer, openDrawer, setPageTitle } from '../actions';

const mapStateToProps = state => {
  return {
    pageTitle: state.ui.pageTitle,
    drawerOpen: state.ui.drawerOpen,
  };
};

const DrawerToogle = connect(mapStateToProps, {
  setPageTitle,
  closeDrawer,
  openDrawer,
})(DrawerToogleComponent);

export default DrawerToogle;
