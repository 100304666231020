import { connect } from 'react-redux';
import { getUserByIdSelector } from '../reducers';
import { setPageTitle as setPageTitleAction } from '../../ui/actions';
import { fetchUserById } from '../actions';
import { enqueueSnackbar as enqueueSnackbarAction } from '../../snackbar/actions';
import UserForm from '../components/user-form';
import { fetchChannels } from '../../channel/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    data: getUserByIdSelector(state, ownProps.id),
    channels: Object.values(state.channels.entities.channels),
  };
};

const mapDispatchToProps = {
  setPageTitle: setPageTitleAction,
  fetchUserById,
  enqueueSnackbar: enqueueSnackbarAction,
  fetchChannels,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
