import { createMuiTheme } from '@material-ui/core';
import theme from '@material-ui/core/styles/defaultTheme';
import { roRO } from '@material-ui/core/locale';

export const palette = {
  brand: '#85883e',
  accent: '#F94848',
  primary: {
    light: '#c8ca4e',
    main: '#a6a736',
    dark: '#686b2d',
    contrastText: '#fff',
  },
  secondary: {
    light: '#f65b5b',
    main: '#F94848',
    dark: '#f63535',
    contrastText: '#fff',
  },
  /* secondary: {
    light: '#484c8c',
    main: '#353868',
    dark: '#272a4e',
    contrastText: '#fff',
  }, */

  warning: {
    main: '#ff8e27',
  },

  background: {
    default: '#ffffff',
  },

  text: {
    primary: '#111026',
  },
};
const themeName = 'SpyShop B2B';

const typography = {
  fontFamily: '"Lato",sans-serif',
  h4: {
    fontSize: '1.875rem',
  },
};

const overrides = {
  '@global': {
    '.d-table': {
      display: 'table',
    },
  },
  MuiApp: {
    root: {
      paddingTop: 52,
    },
  },
  MuiAutocomplete: {
    clearIndicator: {
      padding: '2px !important',
    },
    popupIndicator: {
      padding: '2px !important',
    },
  },
  MuiPaper: {
    elevation1: {
      boxShadow: '0px 14px 60px rgba(0,0,0,0.06)',
    },
    root: {
      '&.FiltersPaper': {
        padding: '8px 16px',
        marginBottom: '16px',
      },

      '&.FormBoxPaper': {
        padding: theme.spacing(3, 3, 2),
        marginBottom: '24px',

        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2),
        },
      },

      '&.ButtonsBoxPaper': {
        padding: '12px 0',
        marginTop: -12,
        marginBottom: '32px',
        background: 'transparent',
        border: 0,
        borderRadius: 0,
        borderTop: '1px solid #ccc',
        boxShadow: 'none',
      },

      '&.ButtonsBoxPaper.PositionTop': {
        marginTop: 0,
        marginBottom: 0,
        borderTop: '0',
        borderBottom: '0',
      },

      '&.ContentPaper-Loading': {
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  MuiButton: {
    iconSizeMedium: {
      '&> *:first-child': {
        fontSize: 16,
      },
    },
    text: {
      padding: theme.spacing(1, 3),
    },
    root: {
      padding: theme.spacing(1, 3),
      textTransform: 'none',
      // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontFamily: '"Lato", sans-serif',
      letterSpacing: '0.02857em',
    },
  },

  MuiInput: {
    input: {
      fontSize: '0.875rem',
      '&.LargeTitleInput': {
        fontSize: 24,
      },
    },
    underline: {
      '&:before': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
      },
    },
  },
  MuiOutlinedInput: {
    notchedOutline: {
      borderColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.16)' : 'rgba(255, 255, 255, 0.23)',
    },
    root: {
      /* background: '#fcfcfc', */
      '&.focused': {
        background: 'green',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
      },
    },
  },
  MuiTextField: {},
  MuiSkeleton: {
    root: {
      '&.LoadingSkeleton': {},
    },
  },
};

const props = {
  // Name of the component
  MuiTextField: {
    variant: 'outlined',
    // The default props to change
    InputLabelProps: {
      /* shrink: true, */
    },
  },
  MuiFormControl: {
    variant: 'outlined',
    /* margin: 'dense', */
  },
  MuiInput: {
    variant: 'outlined',
  },
  MuiButton: {
    /* disableElevation: true, */
  },
};

export default createMuiTheme({ palette, typography, overrides, themeName, props }, roRO);
