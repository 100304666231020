import { connect } from 'react-redux';
import OfferReviewAddPublic from '../components/offer-review-add-public';

function mapStateToProps(state) {
  return {};
}

const OfferReviewAddPublicContainer = connect(
  mapStateToProps,
  {},
)(OfferReviewAddPublic);

export default OfferReviewAddPublicContainer;
