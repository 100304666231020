import React from 'react';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

export const DrawerToogle = ({ classes, drawerOpen, closeDrawer, openDrawer }) => {
  return (
    <IconButton
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        if (drawerOpen) {
          closeDrawer();
          localStorage.setItem('isDrawerOpen', '0');
        } else {
          openDrawer();
          localStorage.setItem('isDrawerOpen', '1');
        }
      }}
      className={classes.menuButton}
      color="inherit">
      <MenuIcon />
    </IconButton>
  );
};

export default DrawerToogle;
